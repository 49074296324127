

export const ServiceUrl = {
    baseURL: window.location.hostname === '172.30.82.8' ? 'http://172.30.82.3:9090' : 'http://103.252.84.12:9090',
    showImageUrl: `${window.location.hostname === 'localhost' ? '' : 'http://103.252.84.12:9090'}/viewImageAsURL/`,
    getEGPContractDetails: "/api/external/auth/getEGPContractDetails",
    signin: "/api/auth/signin",
    addOrUpdateRole: "/api/roles/addOrUpdateRole",
    getRoles: "/api/roles/getRoles",
    getAllUsers: "/api/auth/getAllUserList",
    menuPermission: '/api/menu/',
    permissionUpdate: '/api/menu/saveOrUpdateMenuPermission',
    getAllChapterList: '/api/bsr/getAllChapterList',
    getAllBaseLocationList: '/api/bsr/getAllBaseLocationList',
    getUpdatedRateListForCopyCNBOQ: '/api/bsr/getUpdatedRateListForCopyCNBOQ',
    getUpdatedRateListForCopyCNBOQForProjectLocation: '/api/project/projectLocation/getUpdatedRateListForCopyCNBOQForProjectLocation',
    getAllCategoryList: '/api/bsr/getAllCategoryList',
    getAllChapterTypeList: '/api/bsr/getAllChapterTypeList',
    getAllClassificationList: '/api/bsr/getAllClassificationList',
    getAllSectionList: '/api/bsr/getAllSectionList',
    getAllSubChapterList: '/api/bsr/getAllSubChapterList',
    saveUpdateBaseLocation: '/api/bsr/saveUpdateAndDeleteBaseLocation',
    saveUpdateClassification: '/api/bsr/saveUpdateAndDeleteClassification',
    deleteClassification: '/deleteClassification',
    saveUpdateDeleteSection: '/api/bsr/saveUpdateAndDeleteSection',
    deleteSection: '/deleteSection',
    saveUpdateDeleteChapter: '/api/bsr/saveUpdateAndDeleteChapter',
    deleteChapter: '/deleteChapter',
    getAllRaBillRecoveryList: '/getAllRaBillRecoveryList',
    getAllUnitList: '/api/bsr/getAllUnitList',
    saveUpdateDeleteSubChapter: '/api/bsr/saveUpdateAndDeleteSubChapter',
    deleteSubChapter: '/deleteSubChapter',
    saveUpdateDeleteCategory: '/api/bsr/saveUpdateAndDeleteCategory',
    deleteCategory: '/deleteCategory',
    saveUpdateAndDeleteSubCategory: '/api/bsr/saveUpdateAndDeleteSubCategory',
    deleteSubCategory: "/deleteSubCategory",
    saveUpdateAndDeleteBMLItem: '/api/bsr/saveUpdateAndDeleteBMLItem',
    updateBMLItemBuildUpItemLMC: '/api/bsr/updateBMLItemBuildUpItemLMC',
    saveUpdateAndDeleteBMLItemRate: '/api/bsr/saveUpdateAndDeleteBMLItemRate',
    getAllMenuAndSubMenuByRole: '/getAllMenuAndSubMenuByRole',
    getAllAgencyTypeList: '/api/agency/getAllAgencyTypeList',
    getAllParentAgencyList: '/api/agency/getAllAgencyListByAgencytypeId',
    saveUpdateAgencyDetails: '/api/agency/saveUpdateAgencyDetails',
    saveUpdateAgencyLogo: '/api/agency/saveUpdateAgencyLogo',
    getAllAgencyListByParentId: '/api/agency/getAllAgencyListByParentId',
    checkEmailAvailability: '/api/auth/checkEmailAvailability?email=',
    deleteBMLItemRate: '/deleteBMLItemRate',
    signup: '/api/auth/signup',
    signupForHopa: '/api/auth/signupforHoPA',
    // generateToken: '/oauth2/token',
    validateCIDNumber: '/dcrc_citizen_details_api/1.0.0/citizendetails/',
    getAllPendingHoPAUserList: 'api/auth/getAllPendingHoPAUserList',
    forgotPassword: '/api/auth/forgotPassword',
    resetPassword: '/api/auth/passwordReset',
    getProjectLocationIdBasedOnTenderIdAndInvalidReferenceNumber: '/getProjectLocationIdBasedOnTenderIdAndInvalidReferenceNumber',
    bsrBaseUrl: '/api/bsr/',
    getAllChapterListByClassificationIdAndSectionId: '/api/bsr/getAllChapterListByClassificationIdAndSectionId',
    getAllChapterListForProjectLocationId: '/api/bsr/getAllChapterListForProjectLocationId',
    getAllCategoryListBychapterIdAndSubChapterIdAndSectionId: '/api/bsr/getAllCategoryListBychapterIdAndSubChapterIdAndSectionId',
    getAllSubChapterListById: '/api/bsr/getAllSubChapterListBychapterIdAndClassificationIdAndSectionId',
    getAllSubCategoryList: '/api/bsr/getAllSubCategoryList',
    getAllBMLItemListByCriteria: '/api/bsr/getAllBMLItemListByCriteria',
    getAllStageInformation: "/api/project/stageInformation/getAllStageInformation",
    getAllProjectLocationList: "/api/project/projectLocation/getAllProjectLocationList",
    getAllProjectImplementationType: "/api/project/projectLocation/getAllProjectImplementationTypeList",
    getAllProjectLocationListForManageBSR: "/api/project/projectLocation/getAllProjectLocationListForManageBSR",
    saveUpdateAndDeleteStageInformation: '/api/project/stageInformation/saveUpdateAndDeleteStageInformation',
    saveOrUpdateProjectLocation: '/api/project/projectLocation/saveOrUpdateProjectLocation',
    getAllFundingSourceList: '/api/project/projectLocation/getAllFundingSourceList',
    getAllBMLItemRateListByCriteria: '/api/bsr/getAllBMLItemRateListByCriteria',
    saveUpdateAndDeleteBuildUpItem: '/api/bsr/saveUpdateAndDeleteBuildUpItem',
    saveAndUpdateBuiltUpItemLmcList: '/saveAndUpdateBuiltUpItemLmcList',
    updateBuildUpItemLMCDetails: '/api/bsr/updateBuildUpItemLMCDetails',
    getAllBuildUpItemLMCListByCriteria: '/api/bsr/getAllBuildUpItemLMCListByCriteria',
    getAllBuildUpItemLMCDetailsListByLmcId: '/getAllBuildUpItemLMCDetailsListByLmcId',
    deleteBuildUpLMCDetails: '/deleteBuildUpLMCDetails',
    getAllBuildUpItemLMCListForBuildUpItemRate: '/api/bsr/getAllBuildUpItemLMCListForBuildUpItemRate',
    getWastageWaterOverhead: '/api/bsr/getWastageWaterOverhead',
    saveOrupdateBuildUpItemRate: '/api/bsr/saveOrupdateBuildUpItemRate',
    getAllBuildUpItemLMCDetailsListForBuildUpItemRate: '/api/bsr/getAllBuildUpItemLMCDetailsListForBuildUpItemRate',
    deleteBuildUpLMCRate: '/deleteBuildUpLMCRate',
    deleteBuildUpLMC: '/deleteBuildUpLMC',
    copyCNBOQ: '/api/project/construction/copyCNBOQ',
    fetchCopyCNBOQList: '/fetchCopyCNBOQList',
    deleteProjectLocationBsrBuiltupItems: '/deleteProjectLocationBsrBuiltupItems',
    getAllConstructionTypeList: '/api/project/construction/getAllConstructionTypeList',
    getAllConstructionTypeListForPBOQ: '/getAllConstructionTypeList',
    saveOrUpdateConstructionName: '/api/project/construction/saveOrUpdateConstructionName',
    getAllConstructionNameList: '/getAllConstructionNameListByConstructiontypeIdOrProjectlocationId',
    getAllConstructionNameListForProjectBOQ: '/getAllConstructionNameListByConstructiontypeIdAndProjectlocationIdForProjectBOQ',
    getAllBMLItemRateListByCriteriaForProjectLocation: '/api/project/getAllBMLItemRateListByCriteriaForProjectLocation',
    copyAllBMLItemRateListByCriteriaForProjectLocation: '/api/project/copyAllBMLItemRateListByCriteriaForProjectLocation',
    saveOrupdateProjectLocationBsrBmlitems: '/api/project/saveOrupdateProjectLocationBsrBmlitems',
    deleteProjectLocationBsrBmlitem: '/deleteProjectLocationBsrBmlitem',
    addNewProjectLocationBsrBmlitems: '/api/project/addNewProjectLocationBsrBmlitems',
    getAllBuildUpItemLMCListForProjectLocationByCriteria: '/api/project/getAllBuildUpItemLMCListForProjectLocationByCriteria',
    getAlreadySavedBuildUpItemLMCListForProjectLocationByCriteriaForView: '/api/project/getAlreadySavedBuildUpItemLMCListForProjectLocationByCriteriaForView',
    getAlreadySavedBMLItemRateListByCriteriaForProjectLocationForView: '/api/project/getAlreadySavedBMLItemRateListByCriteriaForProjectLocationForView',
    getAllBuildUpItemLMCDetailsListByLmcIdForProjectLocation: '/getAllBuildUpItemLMCDetailsListByLmcIdForProjectLocation',
    saveUpdateAndDeleteBuildUpItemForProjectLocation: '/api/project/saveUpdateAndDeleteBuildUpItemForProjectLocation',
    getCodeForNewBuildUpItemForProjectLocationByChapterIdAndYear: '/getCodeForNewBuildUpItemForProjectLocationByChapterIdAndYear',
    deleteProjectLocationBsrBuiltupItemsDetails: '/deleteProjectLocationBsrBuiltupItemsDetails',
    updateProjectLocationBsrBuiltUpItems: "/construction/updateProjectLocationBsrBuiltUpItems",
    addNewBuildUpItemForProjectLocation: '/api/project/addNewBuildUpItemForProjectLocation',
    addNewProjectLocationBsrBuiltupItemsDetailsListForProjectLocation: '/api/project/addNewProjectLocationBsrBuiltupItemsDetailsListForProjectLocation',
    getIndividualBuildUpItemListForProjectLocationRateAnalysisById: '/api/project/getIndividualBuildUpItemListForProjectLocationRateAnalysisById',
    getAllBuildUpItemistForProjectLocationRateAnalysisByCriteria: '/api/project/getAllBuildUpItemistForProjectLocationRateAnalysisByCriteria',
    getAllBuildUpItemistForConstructionNameBOQ: '/getAllBuildUpItemistForConstructionNameBOQ',
    getAllBMLItemistForConstructionNameBOQ: '/getAllBMLItemistForConstructionNameBOQ',
    getAllBuiltUpItemListByisBuiltupitemFalse: '/api/bsr/getAllBuiltUpItemListByisBuiltupitemFalse',
    getAllConstructionNameBOQListByConstructionNameId: '/getAllConstructionNameBOQListByConstructionNameId',
    deleteConstructionNameBOQDetails: '/deleteConstructionNameBOQDetails',
    deleteCostIndexWeightageAndRate: '/deleteCostIndexWeightageAndRate',
    deleteConstructionNameById: '/deleteConstructionNameById',
    deleteCopyBsrItem: '/deleteCopyBsrItem',
    isProjectLocationNotPresentInConstructionName: '/isProjectLocationNotPresentInConstructionName',
    generateToken: '/api/external/auth/',
    saveOrUpdateConstructionNameBOQ: '/api/project/construction/saveOrUpdateConstructionNameBOQ',
    calculateLabourAndMaterialRates: '/api/project/construction/calculateLabourAndMaterialRates',
    saveConstructionNameBOQDOM: '/api/project/construction/saveConstructionNameBOQDOM',
    getAllProjectBOQListByCriteria: '/api/project/projectBOQ/getAllProjectBOQListByCriteria',
    saveOrUpdateProjectBOQ: '/api/project/projectBOQ/saveOrUpdateProjectBOQ',
    deleteConstructionNameDOM: '/deleteConstructionNameDOM',
    deleteProjectLocation: '/deleteProjectLocation',
    getAllProjectLocationListForProjectBOQ: '/api/project/projectBOQ/getAllProjectLocationListForProjectBOQ',
    getAllProjectLocationForContractDetails: '/api/project/contractDetails/getAllProjectLocationListForContractDetails',
    saveUpdateBuildingConstructionName: '/api/bsr/saveUpdateBuildingConstructionName',
    deleteBuildingConstructionNameDetailsById: '/deleteBuildingConstructionNameDetailsById',
    getAllBuildingConstructionName: '/getAllBuildingConstructionName',
    calculatePaRate: '/calculatePaRate',
    saveOrUpdateProjectConstructionName: '/api/bsr/projectConstruction/saveOrUpdateProjectConstructionName',
    getQuickCostRate: "/getQuickCostRate",
    saveUpdateQuickCost: '/api/bsr/saveUpdateQuickCost',
    saveUpdateQuickCostRateDetails: '/api/bsr/saveUpdateQuickCostRateDetails',
    getQuickCostRateReport: '/api/bsr/getQuickCostRateReport',
    getAllBuildingType: '/api/bsr/getAllBuildingType',
    deleteBuildingConstructionNameById: "/deleteBuildingConstructionNameById",
    fetchAvgRoomSizeAndRomSizeFactor: '/fetchAvgRoomSizeAndRomSizeFactor',
    getAllProjectConstructionNameList: '/getAllProjectConstructionNameList',
    getAllProjectLocationListForReports: '/api/project/contractDetails/getAllProjectLocationListForReports',
    getAllProjectLocationByAgency: '/api/project/projectLocation/getAllProjectLocationByAgency',
    getAllResourceForecastCategoryList: '/api/implementation/resourceForecast/getAllResourceForecastCategoryList',
    getResourceForecastDetails: '/fetchItemDetailsByProjectLocationforResourceForecast',
    getRecoveryStatementForDepartmentalWorks: '/getRecoveryStatementForDepartmentalWorks',
    getAllResourceRequisitionByProjectLocation: '/getAllResourceRequisitionByProjectLocation',
    getAllMaterialBillByProjectLocationId: '/getAllMaterialBillByProjectLocationId',
    getMachineryHiringListForHiringBill: '/getMachineryHiringListForHiringBill',
    getAllHiringLogbookListByStatusAndProjectLocationId: '/getAllHiringLogbookListByStatusAndProjectLocationId',
    getAllSupplyOrderRequisitionId: '/getAllSupplyOrderRequisitionId',
    getAllSupplyOrderProjectLocationId: '/getAllSupplyOrderProjectLocationId',
    saveOrUpdateResourceRequisition: '/saveOrUpdateResourceRequisition',
    deleteRequisitionItemById: "/deleteRequisitionItemById",
    getAllResourceRequisitionByProjectLocationForApproveOrReview: '/getAllResourceRequisitionByProjectLocationForApproveOrReview',
    getAllRequisitionDetailsByResourceRequisitionId: "/getAllRequisitionDetailsByResourceRequisitionId",
    getItemDetailsForDepartmental: '/getItemDetailsForDepartmental',
    getWorkDoneAmountByProjectLocationId: '/getWorkDoneAmountByProjectLocationId',
    getAllRecoveryStatementByProjectLocationId: '/getAllRecoveryStatementByProjectLocationId',
    saveOrUpdateRecoveryStatement: "/saveOrUpdateRecoveryStatement",
    saveOrUpdateMachineryLogbook: '/api/implementation/musterRoll/saveOrUpdateMachineryLogbook',
    updateResourceRequisition: '/updateResourceRequisition',
    getResourceRequisitionByProjectLocation: '/getResourceRequisitionByProjectLocation',
    saveOrUpdateWorker: '/api/implementation/resourceForecast/saveOrUpdateWorker',
    getMBDetailsByProjectLocationId: '/getMBDetailsByProjectLocationId',
    getAllLogBookDetails: '/getAllLogBookDetails',
    saveOrUpdateSupplyOrder: '/api/implementation/resourceForecast/saveOrUpdateSupplyOrder',
    saveOrUpdateDeploymentOrder: '/api/implementation/resourceForecast/saveOrUpdateDeploymentOrder',
    saveOrUpdateSupplier: '/api/implementation/resourceForecast/saveOrUpdateSupplier',
    getAllSupplier: '/api/implementation/resourceForecast/getAllSupplier',
    getAllSupplierByRequisition: '/getAllSupplierByRequisition',
    getAllDeploymentOrderByProjectLocationId: "/getAllDeploymentOrderByProjectLocationId",
    saveOrUpdateMaterialReceipt: '/api/implementation/musterRoll/saveOrUpdateMaterialReceipt',
    saveOrUpdateWorkerAttendance: '/api/implementation/resourceForecast/saveOrUpdateWorkerAttendance',
    saveProjectRequisitionWorkerMap: '/api/implementation/resourceForecast/saveProjectRequisitionWorkerMap',
    getWorkerByIdentityDocumentNo: "/getWorkerByIdentityDocumentNo",
    getResourceRequisitionDetailsReport: '/getResourceRequisitionDetailsReport',
    getAllWorkerListByResourceRequisitionId: '/getAllWorkerListByResourceRequisitionId',
    saveOrUpdateHiringBill: '/api/implementation/musterRoll/saveOrUpdateHiringBill',
    getAllMachineryLogbookByResourceRequisitionIdAndEntryDate: '/api/implementation/musterRoll/getAllMachineryLogbookByResourceRequisitionIdAndEntryDate',
    getAllMachineryHiringDetailsListByResourceRequisitionId: "/getAllMachineryHiringDetailsListByResourceRequisitionId",
    getMachineryByRegistrationNumber: "/getMachineryByRegistrationNumber",
    saveOrUpdateMachineryHiringDetails: "/saveOrUpdateMachineryHiringDetails",
    getAllProjectUserMapListByProjectLocationId: '/getAllProjectUserMapListByProjectLocationId',
    saveOrUpdateProjectUserMap: '/api/project/projectLocation/saveOrUpdateProjectUserMap',
    getAllProjectRoleList: '/api/project/projectLocation/getAllProjectRoleList',
    getAllUserListByAgencyId: '/api/auth/getAllUserListByAgencyId',
    saveOrUpdateMaterialReceiptList: '/api/implementation/musterRoll/saveOrUpdateMaterialReceiptList',
    assignNewRoleToUser: '/assignNewRoleToUser',
    deleteProjectUserMap: '/deleteProjectUserMap',
    getAllConstructionNameBOQDetailsListByConstructionnameboqId: '/getAllConstructionNameBOQDetailsListByConstructionnameboqId',
    saveOrUpdateContractDetails: '/api/project/contractDetails/saveOrUpdateContractDetails',
    getConstructionNameLevelListForStagePercentage: '/getConstructionNameLevelListForStagePercentage',
    getProjectLevelListForStagePercentage: '/getProjectLevelListForStagePercentage',
    getAllWorkPlanByConstructionlevelOrProjectLevel: '/getAllWorkPlanByConstructionlevelOrProjectLevel',
    getAllWorkPlanDetails: '/getWorkplanDetails',
    saveOrUpdateWorkPlan: '/api/implementation/workPlan/saveOrUpdateWorkPlan',
    getAllContractDetailsList: '/getAllContractDetailsList',
    getAlRunningAccountBillDetailsListById: '/getAllRunningAccountBillDetailsListById',
    getRunningAccountBillByProjectLocationId: '/getRunningAccountBillByProjectLocationId',
    getAllContractBOQRateList: '/getAllContractBOQRateList',
    getCNBoQContractBOQRateList: '/getCNBoQContractRateDetails',
    saveOrUpdateContractBOQRate: '/api/project/contractDetails/saveOrUpdateContractBOQRate',
    saveOrUpdateRABillRateAnalysis: '/api/implementation/runningAccountBill/saveOrUpdateRABillRateAnalysis',
    updateRABillRevisedRateByRunningAccountBillDetailsIds: '/api/implementation/runningAccountBill/updateRABillRevisedRateByRunningAccountBillDetailsIds',
    saveOrUpdateRaBillAmendmentRateAnalysis: '/api/implementation/runningAccountBill/saveOrUpdateRaBillAmendmentRateAnalysis',
    deleteRolefromUser: '/deleteRolefromUser',
    getAllWorkPlanByProjectLevel: '/getAllWorkPlanByProjectLevel',
    deleteContractBOQRate: '/deleteContractBOQRate',
    deleteAdvancePaymentById: '/deleteAdvancePaymentById',
    deletePhysicalProgress: "/deletePhysicalProgress",
    getAllPhysicalProgressByConstructionlevelOrProjectLevel: '/getAllPhysicalProgressByConstructionLeveOrProjectLevel',
    saveOrUpdatePhysicalProgress: '/api/implementation/physicalProgress/saveOrUpdatePhysicalProgress',
    saveOrUpdatePhysicalProgressAmendment: '/api/implementation/physicalProgress/saveOrUpdatePhysicalProgressAmendment',
    getAllPhysicalProgressAmendmentListByProjectLocationId: '/getAllPhysicalProgressAmendmentListByProjectLocationId',
    getAllPhysicalProgressByCriteria: '/getAllPhysicalProgressByCriteria',
    getAllGoodReceiptsListByGoodReceiptIds: '/api/implementation/inventory/getAllGoodReceiptsListByGoodReceiptIds',
    getAllAdvancePaymentListByProjectLocationIdOrStatus: '/getAllAdvancePaymentListByProjectLocationIdOrStatus',
    getAllGoodReceiptListByProjectLocationIdForAdvancePayment: '/getAllGoodReceiptListByProjectLocationIdForAdvancePayment',
    getAllAdvanceTypeList: '/api/implementation/advancePayment/getAllAdvanceTypeList',
    saveOrUpdateAdvancePayment: '/api/implementation/advancePayment/saveOrUpdateAdvancePayment',
    saveOrUpdateRecovery: '/api/implementation/advancePayment/saveOrUpdateRecovery',
    checkBMLPublishStatus: '/api/bsr/dashBoard/checkBMLPublishStatus',
    getBSRPublishDetails: "/api/bsr/getBSRPublishDetails",
    getLMCPublishDetails: "/api/bsr/getLMCPublishDetails",
    checkBuiltUpPublishStatus: '/api/bsr/dashBoard/checkBuiltUpPublishStatus',
    UpdateStatusBMLOrBuiltUpItemsForPublish: '/UpdateStatusBMLOrBuiltUpItemsForPublish',
    getBMLOrBuiltUpItemRateDetails: '/getBMLOrBuiltUpItemRateDetails',
    getAllBMLOrBuiltUpSubChapterList: '/getAllBMLOrBuiltUpSubChapterList',
    getAllBMLOrBuiltUpChapterList: '/getAllBMLOrBuiltUpChapterList',
    getAllBMLOrBuiltUpCategoryList: '/getAllBMLOrBuiltUpCategoryList',
    getAllAllBMLOrBuiltUpSubCategoryList: '/getAllAllBMLOrBuiltUpSubCategoryList',
    getLatestPhysicalProgress: '/getLatestPhysicalProgressByCriteria',
    getAllDzongkhagList: '/api/agency/getAllDzongkhagList',
    getAllWorkTypeList: '/api/project/workType/getAllWorkTypeList',
    saveUpdateWorkType: '/api/project/workType/saveUpdateWorkType',
    deleteWorkTypeById: '/deleteWorkTypeById',
    getAllLiquidityDamageList: '/api/project/configurationParameters/getAllLiquidityDamageList',
    getAllLDLimitList: '/api/project/configurationParameters/getAllLDLimitList',
    getAllEoIContractList: '/api/project/configurationParameters/getAllEoIContractList',
    getAllRetentionList: '/api/project/configurationParameters/getAllRetentionList',
    getAllTDSList: '/api/project/configurationParameters/getAllTDSList',
    getAllVarianceList: '/api/project/configurationParameters/getAllVarianceList',
    saveOrUpdateRetention: '/api/project/configurationParameters/saveOrUpdateRetention',
    saveOrUpdateVariance: '/api/project/configurationParameters/saveOrUpdateVariance',
    saveOrUpdateTDS: '/api/project/configurationParameters/saveOrUpdateTDS',
    saveOrUpdateEoIContract: '/api/project/configurationParameters/saveOrUpdateEoIContract',
    getAllMusterRollListByProjectLocationIdAndPaymentTypeId: '/getAllMusterRollListByProjectLocationIdAndPaymentTypeId',
    getAllPaymentTypeList: '/api/implementation/musterRoll/getAllPaymentTypeList',
    saveUpdateCostIndexWeightage: '/api/project/workType/saveUpdateCostIndexWeightage',
    deleteCostIndexWeightageById: '/deleteCostIndexWeightageById',
    getGewogListByDzongkhagId: '/getGewogListByDzongkhagId',
    saveUpdateCostindexRate: '/api/project/workType/saveUpdateCostindexRate',
    getAllCostindexRateByCriteria: '/api/project/workType/getAllCostindexRateByCriteria',
    getAllCostIndexRateForDateWise: '/getAllCostIndexRateForDateWise',
    getAllGoodReceiptListByProjectLocationId: '/getAllGoodReceiptListByProjectLocationId',
    getAllGoodIssueListByProjectLocationId: '/getAllGoodIssueListByProjectLocationId',
    getAllGoodAdjustmentListByProjectLocationId: '/getAllGoodAdjustmentListByProjectLocationId',
    getAllRunningAccountBillByProjectLocationId: '/getAllRunningAccountBillByProjectLocationId',
    getApprovedRaBillForRecovery: '/getApprovedRaBillForRecovery',
    getRetentionReleaseList: '/getRetentionReleaseList',
    copyBsrAndBuiltUpItems: '/api/project/copy/copyBsrAndBuiltUpItems',
    saveOrUpdateRetentionRelease: '/api/implementation/runningAccountBill/saveOrUpdateRetentionRelease',
    downloadFile: window.location.hostname === '172.30.82.8' ? 'http://172.30.82.3:9090/api/implementation/download/' : window.location.hostname === 'cms.moit.gov.bt' ? 'http://cms.moit.gov.bt/api/implementation/download/' : 'http://103.252.84.8:9090/api/implementation/download/',
    // downloadFile: 'http://103.2.134.43:9090/api/implementation/download/',
    getAllPriceAdjustmentList: '/getAllPriceAdjustmentList',
    getAlStockForGoodReceipt: '/getAlStockForGoodReceipt',
    saveOrUpdateGoodReceipt: '/api/implementation/inventory/saveOrUpdateGoodReceipt',
    deleteGoodReceiptById: '/deleteGoodReceiptById',
    deleteGoodReceiptDetails: '/api/implementation/musterRoll/deleteGoodReceiptDetails',
    updateGoodReceiptDetails: '/api/implementation/musterRoll/updateReceiptDetails',
    getAllProjectLocationBsrBmlitemsByProjectLicationIdForGoodReceipt: '/getAllProjectLocationBsrBmlitemsByProjectLicationIdForGoodReceipt',
    downloadBMLItemRateExcel: '/excel',
    generatePdfBMLRates: '/generatePdfBMLRates',
    generatePdfLMCDetailsForDashBoard: '/generatePdfLMCDetailsForDashBoard',
    // generatePdfBMLRatesForDashBoard: '/generatePdfBMLRatesForDashBoard',
    generateMultipleBaseLocationPdfBMLRatesForDashBoard: "/generateMultipleBaseLocationPdfBMLRatesForDashBoard",
    uploadBMLItemRateExcelL: '/api/bsr/import/excel',
    exportExcelBuildUp: '/export/excelBuildUp',
    importExcelBuildUp: '/api/bsr/import/excelBuildUp',
    saveOrUpdateMusterRoll: '/api/implementation/musterRoll/saveOrUpdateMusterRoll',
    getAllMachineryTypeList: '/api/implementation/musterRoll/getAllMachineryTypeList',
    getAllMachineryListByMachineryTypeId: '/getAllMachineryListByMachineryTypeId',
    saveOrUpdateGoodIssue: '/api/implementation/inventory/saveOrUpdateGoodIssue',
    saveUpdateItemCatalogue: "/api/implementation/catalogue/saveUpdateItemCatalogue",
    saveOrUpdateGoodAdjustment: '/api/implementation/inventory/saveOrUpdateGoodAdjustment',
    deleteItemCatalogueListById: '/deleteItemCatalogueListById',
    getItemCatalogueListById: '/getItemCatalogueListById',
    populateItemDetailsForIssueOrAdjustment: '/populateItemDetailsForIssueOrAdjustment',
    getAllPriceAdjustmentCategoryList: '/api/implementation/priceAdjustment/getAllPriceAdjustmentCategoryList',
    saveOrUpdateMaterialLabourIndex: '/api/implementation/priceAdjustment/saveOrUpdateMaterialLabourIndex',
    deleteMaterialLAbourIndex: '/deleteMaterialLabourIndexById',
    saveOrUpdatePriceAdjustment: '/api/implementation/priceAdjustment/saveOrUpdatePriceAdjustment',
    updateUserDetails: '/api/auth/updateUserDetails',
    getAllSupplierOrderBySupplier: '/getAllSupplierOrderBySupplier',
    getAllMaterialReceiptBySupplierOrderList: '/getAllMaterialReceiptBySupplierOrderList',
    getAllAssignedProjectList: '/api/project/projectLocation/getAllAssignedProjectList',
    saveOrUpdateMaterialBill: '/api/implementation/resourceForecast/saveOrUpdateMaterialBill',
    getMaterialBillBySupplier: '/getMaterialBillBySupplier',
    getAdvancePaymentVerificationCount: '/api/implementation/dashBoard/getAdvancePaymentVerificationCount ',
    getAdvancePaymentApprovalCount: '/api/implementation/dashBoard/getAdvancePaymentApprovalCount ',
    getAllProjectRoleListByUserId: 'api/project/projectLocation/getAllProjectRoleListByUserId',
    saveOrUpdateRaBillAmendment: 'api/implementation/runningAccountBill/saveOrUpdateRaBillAmendment',
    getRABillRateAnalysis: 'api/implementation/runningAccountBill/getRABillRateAnalysis',
    getRaBillAmendmentRateAnalysis: 'api/implementation/runningAccountBill/getRaBillAmendmentRateAnalysis',
    saveOrUpdateRunningAccountBill: 'api/implementation/runningAccountBill/saveOrUpdateRunningAccountBill',
    getRetentionAmountByProjectLocationId: '/getRetentionAmountByProjectLocationId',
    saveOrUpdateRaBillRecovery: 'api/implementation/runningAccountBill/saveOrUpdateRaBillRecovery',
    saveOrUpdateRaBillRecoveryDetailsDoM: 'api/implementation/runningAccountBill/saveOrUpdateRaBillRecoveryDetailsDoM',
    updateBalanceQuantity: 'api/implementation/runningAccountBill/updateBalanceQuantity',
    deleteRunningAccountBillDetailsDOM: '/deleteRunningAccountBillDetailsDOM',
    deleteRunningAccountBillId: '/deleteRunningAccountBillId',
    deleteRaBillAmendmentDetails: '/deleteRaBillAmendmentDetails',
    deleteRaBillAmendmentDetailsDOM: '/deleteRaBillAmendmentDetailsDOM',
    saveRunningAccountBillDetailsDOM: 'api/implementation/runningAccountBill/saveRunningAccountBillDetailsDOM',
    saveRaBillAmendmentDetailsDOM: 'api/implementation/runningAccountBill/saveRaBillAmendmentDetailsDOM',
    deleteRaBillRecoveryDetails: '/deleteRaBillRecoveryDetails',
    deleteRaBillRecoveryDetailsDom: '/deleteRaBillRecoveryDetailsDom',
    getRABillDoMList: '/getRABillDoMList',
    deleteProjectBOQ: '/deleteProjectBOQ',
    deleteAllProjectBOQ: '/deleteAllProjectBOQ',
    downloadDOMExcel: '/api/project/construction/export/domExcel',
    uploadDOMExcel: '/api/project/construction/import/domExcel',
    getDataForEGP: '/getDataForEGP',
    getReportsRoles: '/api/roles/getReportsRoles',
    getReportCategories: '/api/roles/getReportCategoryList',
    saveOrUpdateAgencyType: '/api/agency/saveOrUpdateAgencyType',
    saveUpdateUnit: '/api/bsr/saveUpdateUnit',
    getBSRPublishYearList: '/api/bsr/getBSRPublishYearList',
    getLMCPublishYear: '/api/bsr/getLMCPublishYear',
    getOngoingProjectDetailsForLandingPage: '/api/project/landing/getOngoingProjectDetailsForLandingPage',
    saveOrUpdateConstructionType: '/api/project/construction/saveOrUpdateConstructionType',
    fetchItemDetailsByProjectLocationforCostIndex: '/fetchItemDetailsByProjectLocationforCostIndex',
    fetchItemRateWeightDetailsByProjectLocationforCostIndex: '/fetchItemRateWeightDetailsByProjectLocationforCostIndex',
    displayProjectDetailsForPriceAdjustment: '/api/project/contractDetails/displayProjectDetailsForPriceAdjustment',
    getAllBMLItemRateListByCriteriaForProjectLocationForManageBSRView: '/getAllBMLItemRateListByCriteriaForProjectLocationForManageBSRView',
    getAllAssignedReportDetailList: '/getAllAssignedReportDetailList',
    saveOrUpdateRoleReportMap: '/api/roles/saveOrUpdateRoleReportMap',
    getAllMaterialLabourIndex: '/getAllMaterialLabourIndex',
    getAllWorkTypeItemsForBSRSample: '/getAllWorkTypeItemsForBSRSample',
    deleteRoleById: '/deleteRoleById',
    approveOrRejectHoPA: '/approveOrRejectHoPA',
    deleteAgencyType: '/deleteAgencyType',
    deleteAgency: '/deleteAgency',
    deleteBaseLocation: '/deleteBaseLocation',
    updateTenderDetails: '/updateTenderDetails',
    getAllRaBillAmendmentByRunningAccountBillId: '/getAllRaBillAmendmentByRunningAccountBillId',
    deletePartIIIAmendmentDetails: '/deletePartIIIAmendmentDetails',
    savePartIIIAmendmentDetailsDOM: '/api/implementation/runningAccountBill/savePartIIIAmendmentDetailsDOM',
    getPartIIIAmendmentRateAnalysis: '/api/implementation/runningAccountBill/getPartIIIAmendmentRateAnalysis',
    deletePartIIIAmendmentDetailsDOM: '/deletePartIIIAmendmentDetailsDOM',
    saveOrUpdatePartIIIAmendmentRateAnalysis: '/api/implementation/runningAccountBill/saveOrUpdatePartIIIAmendmentRateAnalysis',
    getAllPartIIIAmendmentByProjectLocationId: '/getAllPartIIIAmendmentByProjectLocationId',
    saveOrUpdatePartIIIAmendment: '/api/implementation/runningAccountBill/saveOrUpdatePartIIIAmendment',
    getAllProjectLocationListForAll: '/api/project/contractDetails/getAllProjectLocationListForAll',
    getAllRecoveryListByProjectLocationIdAndPaymentcategoryId: '/getAllRecoveryListByProjectLocationIdAndPaymentcategoryId',
    getAllItemCatalogueList: '/api/implementation/catalogue/getAllItemCatalogueList',
    getRunningAccountBillDetailsForVerificationList: '/getRunningAccountBillDetailsForVerification',
    getDebitCreditDetailsForRABillReport: "/getDebitCreditDetailsForRABillReport",
    getAllPriceAdjustmentReport: '/getAllPriceAdjustmentReport',
    getAllAdvanceDetailsReport: '/getAllAdvanceDetailsReport',
    getAllGoodIssueListByRunningAccountBillIdForAdvanceRecovery: "/getAllGoodIssueListByRunningAccountBillIdForAdvanceRecovery",
    getAllHiringAgencyList: '/api/implementation/hiringAgency/getAllHiringAgencyList',
    getAllHiringAgencyListByResourceRequisitionId: '/getAllHiringAgencyListByResourceRequisitionId',
    getMachineryHiringTypeList: '/api/implementation/hiringAgency/getMachineryHiringTypeList',
    saveOrUpdateHiringAgency: '/api/implementation/hiringAgency/saveOrUpdateHiringAgencyService',
    getAllMachineryList: '/api/implementation/musterRoll/getAllMachineryList',
    saveOrUpdateMachinery: '/api/implementation/musterRoll/saveOrUpdateMachinery',
    getAllAdvancePaymentListForRecovery: '/getAdvancePaymentDetailsForRecovery',
    downloadAuthenticationFile: '/api/authentication/download/',
    downloadImplementationFile: '/api/implementation/download/',
    getProjectBoQDetailsByConstructionName: '/getProjectBoQDetails',
    checkItemPresentInRunningAccountBill: '/api/implementation/runningAccountBill/checkItemPresentInRunningAccountBill'
}   