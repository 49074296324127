import { CssBaseline, Icon, IconButton } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import { SnackbarProvider } from 'notistack';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import './index.css'
import { useSnackbar } from 'notistack';

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <Icon style={{ color: '#FFF', fontSize: 'small' }}>
        close
      </Icon>
    </IconButton>
  );
}
ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <CssBaseline />
      <SnackbarProvider autoHideDuration={6000} anchorOrigin={{ horizontal: "center", vertical: "top" }} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
        <App />
      </SnackbarProvider>
    </BrowserRouter>
  </StyledEngineProvider>,
  document.getElementById('root')
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
