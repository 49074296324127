import { CloudDownload, Print } from "@mui/icons-material";
import { Box, Button, Card, FormLabel, Grid, Icon, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { Loader } from "app/components";
import { getBMLItemsForReport, getMusterRollBillDetailsReport } from "app/redux/actions/ReportsAction";
import { getAllProjectLocationByAgency, getAllResourceRequisitionByProjectLocation } from "app/redux/actions/ResourceForeCastActions";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from "app/utils/Constants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { DecimalInputField, formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import jsPDF from "jspdf";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";


const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

export const MusterRollBillDetailsReport = () => {
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [projectDetails, setProjectDetails] = useState()
  const [bmlItemsList, setBmlItemsList] = useState()

  const [requisitionList, setRequisitionList] = useState()
  const { projectLocationListForAgency } = useSelector((state) => state?.resourceForecast)
  const [tableData, setTableData] = useState([])
  const [totalAmount, setTotalAmount] = useState()
  const [state, setState] = useState({
    project_location_id: '',
  })

  const months =
    [
      {
        value: 1,
        month: "January",
        max: 31
      },
      {
        value: 2,
        month: "February",
        max: 30
      },
      {
        value: 3,
        month: "March",
        max: 31
      },
      {
        value: 4,
        month: "April",
        max: 30
      },
      {
        value: 5,
        month: "May",
        max: 31
      },
      {
        value: 6,
        month: "June",
        max: 30
      },
      {
        value: 7,
        month: "July",
        max: 31
      },
      {
        value: 8,
        month: "August",
        max: 31
      },
      {
        value: 9,
        month: "September",
        max: 30
      },
      {
        value: 10,
        month: "October",
        max: 31
      },
      {
        value: 11,
        month: "November",
        max: 30
      },
      {
        value: 12,
        month: "December",
        max: 31
      }]


  useEffect(() => {
    dispatch(getAllProjectLocationByAgency(showLoader, hideLoader, showToast))
  }, []);

  const showLoader = () => {
    setLoading(true)
  }
  const showToast = (message, type) => {
    const key = enqueueSnackbar(message ? message : 'Something went wrong', { variant: type === 'info' ? 'info' : 'error' });
    type === "info" ? SnackBarProperty.info(key, closeSnackbar) : SnackBarProperty.error(key, closeSnackbar)

  }

  const hideLoader = () => {
    setLoading(false)
  }

  const handleChange = (event, source) => {
    setTableData()
    setProjectDetails()
    if (event.target.name === "project_location_id") {
      setRequisitionList()
      setBmlItemsList()
      const filteredArray = projectLocationListForAgency.filter(project => {
        return project.project_location_id === event.target.value
      });


      setState({
        [event.target.name]: event.target.value,
        project_name: filteredArray[0].project_name,
        project_location_id: filteredArray[0].project_location_id,
        project_id: filteredArray[0].project_id,
        project_location: filteredArray[0].project_location,
        //    muster_roll_no:"",
        //    muster_roll_date:"",
        month: null,
        //    musterrollbill_id:""
      });
      setLoading(true)
      getAllResourceRequisitionByProjectLocation({ project_location_id: event.target.value }).then(res => {
        setLoading(false)
        //   handleGetBillList()
        if (res?.status < 300) {
          if (res?.data?.obj?.length > 0) {
            //setRequisitionList(res?.data?.obj)
            // Filter the data based on resourceforecast_category_id equal to 3
            const filteredData = res.data.obj.filter(item => item.resourceforecast_category_id === 1);

            // Set the filtered data to the requisitionList state
            setRequisitionList(filteredData);
          }
          else {
            const key = enqueueSnackbar("No requisition found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }


        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setLoading(false)
        //   handleGetBillList()
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })


    }
    else if (event.target.name === "requisition_id") {
      setBmlItemsList()
      const filteredArray = requisitionList.filter(requisition => {
        return requisition.resourcerequisition_id === event.target.value
      });
      const newState = { ...state }
      setState({
        [event.target.name]: event.target.value,
        project_name: newState.project_name,
        project_location_id: newState.project_location_id,
        project_id: newState.project_id,
        project_location: newState.project_location,
        req_no: filteredArray[0].requisition_no,
        req_date: moment(filteredArray[0].requisition_date).format("DD-MM-YYYY"),
        req_year: filteredArray[0].req_year,
        //  muster_roll_no:"",
        //  muster_roll_date:"",
        month: null
        //   category: filteredArray[0].resourceforecast_category_name
      });
      setLoading(true)
      getBMLItemsForReport(event.target.value).then(res => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.success) {
            if (res?.data?.obj?.item?.length > 0) {
              setBmlItemsList(res?.data?.obj?.item)
            }
            else {
              const key = enqueueSnackbar("No BML items found", { variant: 'info' });
              SnackBarProperty.info(key, closeSnackbar);
            }
          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }
        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setLoading(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    }
    else if (event.target.value === "month") {
      const newState = { ...state }
      setState({
        [event.target.name]: event.target.value,
        requisition_id: newState?.requisition_id,
        project_name: newState.project_name,
        project_location_id: newState.project_location_id,
        project_id: newState.project_id,
        project_location: newState.project_location,
        req_no: newState.requisition_no,
        req_date: moment(newState?.requisition_date).format("DD-MM-YYYY"),
        req_year: newState.req_year
      })
    }
    else if (event.target.value === "year") {
      setState({ ...state, [event.target.name]: parseInt(event.target.value) })
    }
    else if (event.target.name === "resourcerequisition_details_id") {
      const filteredArray = bmlItemsList.filter(item => {
        return item.resourcerequisition_details_id === event.target.value
      });
      setState({
        ...state,
        [event.target.name]: event.target.value,
        bml_item: filteredArray[0]?.bml_item,
        unit: filteredArray[0]?.unit_name
      })
    }
    else {
      setState({ ...state, [event.target.name]: event.target.value })
    }


  }

  const handleFormSubmit = () => {
    setTableData([])
    setProjectDetails()
    if (state?.year === undefined || state?.remarks === null) {
      const key = enqueueSnackbar("Please enter year", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else if (state?.year?.length !== 4) {
      const key = enqueueSnackbar("Please enter valid year", { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    }
    else {
      setLoading(true)
      getMusterRollBillDetailsReport(state?.project_location_id, state?.requisition_id, 0, state?.month, state?.year).
        then(res => {
          setLoading(false)
          if (res?.status < 300) {
            if (res?.data?.obj?.item) {
              const filteredMonth = months?.filter((elem) => elem?.value === state?.month)


              if (res?.data?.obj?.item?.muster_Roll_Bill_Details_Report_report?.musterRollBillDetailsList?.length > 0) {
                setTableData(res?.data?.obj?.item?.muster_Roll_Bill_Details_Report_report?.musterRollBillDetailsList)
                const data = {
                  project_location: res?.data?.obj?.item?.project_location,
                  project_name: res?.data?.obj?.item?.project_name,
                  project_id: res?.data?.obj?.item?.project_id,
                  requisition_no: state?.req_no,
                  requisition_date: moment(state?.req_date).format("DD-MM-YYYY"),
                  bml_item: state?.bml_item,
                  unit: state?.unit,
                  month: filteredMonth[0]?.month,
                  year: state?.year,
                  muster_roll_no: res?.data?.obj?.item?.muster_Roll_Bill_Details_Report_report?.musterroll_no,
                  muster_roll_date: moment(res?.data?.obj?.item?.muster_Roll_Bill_Details_Report_report?.musterroll_date).format("DD-MM-YYYY")

                }
                setProjectDetails(data)
              }
              else {
                const key = enqueueSnackbar("No items found", { variant: 'info' });
                SnackBarProperty.info(key, closeSnackbar);
              }
            }

          }
          else {
            const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
            SnackBarProperty.error(key, closeSnackbar);

          }
        }).catch(err => {
          setLoading(false)
          const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        })
    }


  }

  useEffect(() => {
    if (tableData?.length > 0) {
      setTotalAmount(tableData?.reduce((acc, item) => acc + item?.amount, 0))
    }
  }, [tableData])


  const handleReportDownload = (isPrint) => {
    const doc = new jsPDF();
    const contentHeight = 150;
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    // html2canvas(headerImage, {
    //   useCORS: true, // Add this line if the image is from a different domain
    //   allowTaint: true, // Add this line if the image is from a different domain
    // }).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");

    // Calculate the width and height of the header image based on the PDF page width
    //   const pdfWidth = doc.internal.pageSize.getWidth() - 50;
    //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    //   const headerHeight = pdfHeight;
    //   const tableY = headerHeight + 15;
    // Insert the header image into the PDF
    const topBorderPadding = 25; // Adjust the padding value as needed
    doc.setDrawColor(255, 255, 255); // Black color for border
    doc.setLineWidth(topBorderPadding); // Border width (padding)

    // Draw the top border (padding)
    // doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

    // Insert the header image into the PDF
    //   doc.addImage(imgData, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight);
    const textX = 15;
    const textY = 20 + 5;
    doc.setFont('helvetica', 'bold'); // Set font to bold
    doc.setFontSize(12); // Set font size

    doc.text("Muster Roll Bill Details Report", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("Muster Roll Bill Details Report") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), topBorderPadding);

    doc.setFontSize(8); // Set font size
    doc.text("Project Location: ", 15, topBorderPadding + 20);
    const firstTextWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${projectDetails?.project_location}`, 15 + firstTextWidth + 5, topBorderPadding + 20);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Name: ", 15, topBorderPadding + 25);
    const secondTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${projectDetails?.project_name}`, 15 + secondTextWidth + 5, topBorderPadding + 25);
    doc.setFont('helvetica', 'bold');
    doc.text("Project ID: ", 15, topBorderPadding + 30);
    const thirdTextWidth = doc.getStringUnitWidth("Project Id:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${projectDetails?.project_id}`, 15 + thirdTextWidth + 5, topBorderPadding + 30);
    doc.setFont('helvetica', 'bold');
    doc.text("Requisition No.: ", 15, topBorderPadding + 35);
    const fourthTextWidth = doc.getStringUnitWidth("Requisition No.: ") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${projectDetails?.requisition_no}`, 15 + fourthTextWidth + 5, topBorderPadding + 35);
    doc.setFont('helvetica', 'bold');
    doc.text("Requisition Date:", 15, topBorderPadding + 40);
    const fifthText = doc.getStringUnitWidth("Requisition Date: ") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${projectDetails?.requisition_date}`, 15 + fifthText + 5, topBorderPadding + 40);
    // doc.setFont('helvetica', 'bold');
    // doc.text("BML Item:", 15, topBorderPadding + 45);
    // const sixthTextWidth = doc.getStringUnitWidth("BML Item:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    // doc.setFont('helvetica', 'normal');
    // doc.text(`${projectDetails?.bml_item}`, 15 + sixthTextWidth + 5, topBorderPadding + 45);
    // doc.setFont('helvetica', 'bold');
    // doc.text("Unit:", 15, topBorderPadding + 50);
    // const seventhTextWidth = doc.getStringUnitWidth("Unit:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    // doc.setFont('helvetica', 'normal');
    // doc.text(`${projectDetails?.unit}`, 15 + seventhTextWidth + 5, topBorderPadding + 50);
    doc.setFont('helvetica', 'bold');
    doc.text("Month: ", 15, topBorderPadding + 45);
    const eighthTextWidth = doc.getStringUnitWidth("Month: ") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${projectDetails?.month}`, 15 + eighthTextWidth + 5, topBorderPadding + 45);
    doc.setFont('helvetica', 'bold');
    doc.text("Year: ", 15, topBorderPadding + 50);
    const ninthTextWidth = doc.getStringUnitWidth("Year: ") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${projectDetails?.year}`, 15 + ninthTextWidth + 5, topBorderPadding + 50);
    doc.setFont('helvetica', 'bold');
    doc.text("Muster Roll No.: ", 15, topBorderPadding + 55);
    const tenthTextWidth = doc.getStringUnitWidth("Muster Roll No.: ") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${projectDetails?.muster_roll_no}`, 15 + tenthTextWidth + 5, topBorderPadding + 55);
    doc.setFont('helvetica', 'bold');
    doc.text("Muster Roll Date: ", 15, topBorderPadding + 60);
    const eleventhTextWidth = doc.getStringUnitWidth("Muster Roll Date: ") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${projectDetails?.muster_roll_date}`, 15 + eleventhTextWidth + 5, topBorderPadding + 60);

    doc.setFont('helvetica', 'normal');

    const headerSectionHeight = 60; // Adjust the value as needed

    // Calculate the starting Y-coordinate for the table
    const tableStartY = headerSectionHeight;
    doc.setFont('helvetica', 'normal');
    const body = []


    tableData && tableData?.length > 0 && tableData?.forEach((elem, index) => {

      body.push(
        [index + 1,
        elem?.category,
        elem?.unit_name,
        elem?.worker_name,
        { content: elem?.noofdays, styles: { halign: "center" } },
        { content: formatNumberWithCommasAndDecimals(elem?.rate ? elem?.rate : 0), styles: { halign: "right" } },
        { content: formatNumberWithCommasAndDecimals(elem?.amount ? elem?.amount : 0), styles: { halign: "right" } }
        ]
      )



    })
    if (tableData?.length > 0) {
      body.push([{ content: "Total Amount (Nu) ", colSpan: 6, styles: { fontStyle: "bold", valign: "middle", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold" } }])
    }
    doc.autoTable({
      startY: topBorderPadding + 70,
      head: [[
        { content: "Sl No.", colSpan: 1, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
        { content: "Category", colSpan: 1, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
        { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
        { content: "Worker Name", colSpan: 1, styles: { valign: "middle", halign: "left", cellPadding: 2, fontSize: 8 } },
        { content: "No of Days", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
        { content: "Rate (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } },
        { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "right", cellPadding: 2, fontSize: 8 } }
      ]],
      body: body, // Use the transformed data here
      theme: "grid",
      styles: {
        fillColor: [255, 255, 255], // Remove background color from the table
        lineColor: [0, 0, 0], // Set border color for main table
        lineWidth: 0.1, // Set border width for main table
        textColor: [0, 0, 0], // Set text color to black
        cellPadding: 2, // Add padding to cells
      },
      columnStyles: {
        1: { cellWidth: 60 },
      },
    });

    // Function to add footer on each page
    //   function addFooter(pageNumber) {
    //     // const footerText = "Hello CMS User";
    //     // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
    //     const footerX = 0; // Starting X position for the footer
    //     const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
    //     const pdfWidth = doc.internal.pageSize.getWidth();
    //     const pdfHeight = 10;
    //     doc.addImage(`${ServiceUrl?.showImageUrl}${state?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
    //   }

    // Get the total number of pages
    //   const totalNumPages = doc.internal.getNumberOfPages();

    //   // Loop through each page to add the footer with page numbers
    //   for (let i = 1; i <= totalNumPages; i++) {
    //     doc.setPage(i);
    //     addFooter(i);
    //   }


    const scaleFactor = 1.0; // Adjust this value as needed
    const pdfBlob = doc.output('blob', { scale: scaleFactor });

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open a new window with the PDF URL

    if (isPrint) {
      const newWindow = window.open(pdfUrl, '_blank');

      // After the new window is opened, you can close the URL object
      URL.revokeObjectURL(pdfUrl);
      if (newWindow) {
        newWindow.onload = () => {
          setIsPrinting(false)
          setIsDownloading(false)
          // Wait for the window to load and then trigger the print dialog
          newWindow.print();
        };
      }
    }

    setIsPrinting(false)
    setIsDownloading(false)

    isPrint === false && doc.save('Muster_Roll_Bill_Details_Report.pdf');


  };


  return (
    <>
      <Container>
        <div style={{ height: 'auto', width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>

          <Grid container>

            <Grid item xs={12}>
              <Button
                size="small"
                sx={{ width: "100px" }}
                variant="contained"
                color="error"
                onClick={() => { window.history.back() }}
              >
                <Icon color="primary" fontSize="small">
                  skip_previous
                </Icon>
                Back
              </Button>
            </Grid>
          </Grid>
          <SimpleCard>

            <ValidatorForm onSubmit={handleFormSubmit}>
              <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
                <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Muster Roll Bill Details</b></Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item sm={4} md={3} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    label="Project ID"
                    id="project_location_id"
                    variant="outlined"
                    name="project_location_id"
                    value={state?.project_location_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Project ID is required"]}
                  >
                    {projectLocationListForAgency?.map((item) => (
                      <MenuItem value={item?.project_location_id} key={item?.project_id}>
                        {item?.project_id}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
                </Grid>
                <Grid item sm={4} md={3} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    label="Requisition No."
                    id="requisition_id"
                    variant="outlined"
                    name="requisition_id"
                    value={state?.requisition_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Requisition Id is required"]}
                  >
                    {requisitionList?.map((item) => (
                      <MenuItem value={item?.resourcerequisition_id} key={item?.resourcerequisition_id}>
                        {item?.requisition_no}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>

                </Grid>
                {/* <Grid item sm={4} md={3} xs={12}>
        <DecoratedTextField
          
          size="small"
          fullWidth
          disabled
          label="Requisition Date"
          id="req_date"
          variant="outlined"
          name="req_date"
          value={state?.req_date?state?.req_date:""}
          validators={["required"]}
          errorMessages={["Requisition date is required"]}
        >
        </DecoratedTextField>
      </Grid> */}
                {/* <Grid item sm={4} md={3} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    label="BML Item"
                    id="item"
                    variant="outlined"
                    name="resourcerequisition_details_id"
                    value={state?.resourcerequisition_details_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["BML Item is required"]}
                  >
                    {bmlItemsList?.map((item) => (
                      <MenuItem value={item?.resourcerequisition_details_id} key={item?.value}>
                        {item?.bml_item}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>

                </Grid> */}
                {/* <Grid item sm={4} md={3} xs={12}>
               <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    label="Muster Roll No"
                    id="item"
                    variant="outlined"
                    name="musterrollbill_id"
                    value={state?.musterrollbill_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Muster roll bill no is required"]}
                  >
                    {billList?.map((item) => (
                      <MenuItem value={item?.musterrollbill_id} key={item?.musterrollbill_id}>
                        {item?.musterroll_no}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>

                </Grid> */}
                {/* <Grid item sm={4} md={2} xs={12}>
        <DecoratedTextField
          
          size="small"
          fullWidth
          disabled
          label="Unit"
          id="unit_name"
          variant="outlined"
          name="unit"
          value={state?.unit?state?.unit:""}
          validators={["required"]}
          errorMessages={["Unit is required"]}
        >
        </DecoratedTextField>
               </Grid> */}
                <Grid item sm={4} md={2} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    label="Month"
                    id="month"
                    variant="outlined"
                    name="month"
                    value={state?.month}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Month is required"]}
                  >
                    {months?.map((item) => (
                      <MenuItem value={item?.value} key={item?.value}>
                        {item?.month}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>

                </Grid>
                <Grid item sm={4} md={2} xs={12}>
                  <DecimalInputField
                    size="small"
                    fullWidth
                    label="Year"
                    id="year"
                    variant="outlined"
                    name="year"
                    value={state?.year}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Year is required"]}
                    onKeyDown={(event) => {
                      if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                        event?.key === "-" || event?.key === "+" || event?.key === "." || event?.key?.toLowerCase() === "e") {
                        event.preventDefault();
                      }

                    }}
                  />

                </Grid>



                <Grid item sm={2} md={2} xs={12} sx={{
                  display: "flex", justifyContent: "flex-start", verticalAlign: 'middle', alignItems: "center"
                }} style={{ marginTop: '-18px' }}>

                  <Button size="medium" color="success" variant="contained" type="submit"  >
                    View
                  </Button>

                </Grid>
              </Grid>



            </ValidatorForm>
          </SimpleCard>
          {
            tableData && tableData?.length > 0 &&
            <Grid container>
              <Grid item xs={12}>
                <Box component="span" display="flex" justifyContent="flex-end">
                  <Button
                    size="small"
                    sx={{ width: "130px", marginLeft: 4 }}
                    variant="contained"
                    color="warning"
                    startIcon={<CloudDownload />}
                    onClick={() => { handleReportDownload(false) }}
                  >
                    Download
                  </Button>
                  <Button
                    size="small"
                    sx={{ width: "100px", marginLeft: 2 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Print />}
                    onClick={() => { handleReportDownload(true) }}
                  >
                    Print
                  </Button>
                </Box>
              </Grid>

            </Grid>}
          {/* table */}
          {
            tableData && tableData?.length > 0 &&
            <Card sx={{ padding: 3 }}>

              <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
                <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
                  {/* <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
          <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${state?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

        </div> */}

                  <Grid item xs={12} align='center' >
                    <FormLabel sx={{ fontSize: 22 }} ><b>Muster Roll Bill Details Report</b></FormLabel>
                  </Grid>


                  <Grid container >
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{projectDetails?.project_location}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{projectDetails?.project_name}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project ID: </b>{projectDetails?.project_id}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Requisition No.: </b>{projectDetails?.requisition_no}</FormLabel>&nbsp;&nbsp;&nbsp;
                      <FormLabel sx={{ fontSize: 18 }}><b>Requisition Date: </b>{projectDetails?.requisition_date}</FormLabel>
                    </Grid>

                    {/* <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>BML Item: </b>{projectDetails?.bml_item}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Unit: </b>{projectDetails?.unit}</FormLabel>
                    </Grid> */}
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Month: </b>{projectDetails?.month}</FormLabel>&nbsp;&nbsp;&nbsp;
                      <FormLabel sx={{ fontSize: 18 }}><b>Year: </b>{projectDetails?.year}</FormLabel>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Muster Roll No.: </b>{projectDetails?.muster_roll_no}</FormLabel>&nbsp;&nbsp;&nbsp;
                      <FormLabel sx={{ fontSize: 18 }}><b>Muster Roll Date: </b>{projectDetails?.muster_roll_date}</FormLabel>
                    </Grid>

                  </Grid>
                </div>

                <div id="tableData">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead style={{ backgroundColor: '#87CEEB' }}>
                        <TableRow>
                          <TableCell align="center">SL No.</TableCell>
                          <TableCell align="left">Category</TableCell>
                          <TableCell align="left">Unit</TableCell>
                          <TableCell align="left">Worker Name</TableCell>
                          <TableCell align="center">No Of Days</TableCell>
                          <TableCell align="right">Rate (Nu)</TableCell>
                          <TableCell align="right">Amount (Nu)&nbsp;&nbsp;</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          tableData?.map((elem, index) => {
                            return (
                              <TableRow>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell align="left">{elem?.category}</TableCell>
                                <TableCell align="left">{elem?.unit_name}</TableCell>
                                <TableCell align="left">{elem?.worker_name}</TableCell>
                                <TableCell align="center">{elem?.noofdays}</TableCell>
                                <TableCell align="right">{formatNumberWithCommasAndDecimals(elem?.rate ? elem?.rate : 0)}</TableCell>
                                <TableCell align="right">{formatNumberWithCommasAndDecimals(elem?.amount ? elem?.amount : 0)}&nbsp;</TableCell>
                              </TableRow>
                            )
                          })

                        }
                        {tableData?.length > 0 &&
                          <TableRow>
                            <TableCell colSpan={6} align="right"><b>Total Amount (Nu)</b></TableCell>
                            <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b>&nbsp;</TableCell>
                          </TableRow>
                        }

                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </div>
            </Card>}

        </div>
        {loading && (
          <Loader
            show={loading}
          />
        )}
        {(isPrinting || isDownloading) && (
          <Loader
            show={isPrinting ? isPrinting : isDownloading}
            text="Please Wait While Generating PDF..."
          />
        )}
      </Container>
    </>
  )
}