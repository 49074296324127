import { CloudDownload, Print,Sync } from "@mui/icons-material";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { Box, Button, Card, FormControlLabel, FormLabel, Grid, Icon, MenuItem, Paper, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Loader } from "app/components";
import { getAllSupplyOrderForReport, getReceiptDetailsReport, getSupplyOrderDetailsReport } from "app/redux/actions/ReportsAction";
import { getAllProjectLocationByAgency } from "app/redux/actions/ResourceForeCastActions";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from "app/utils/Constants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { cmsDateFormat } from "app/utils/constant";
import { formatNumberWithCommasAndDecimals } from "app/utils/utils";
import { SimpleCard } from "components";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const styles = {
  tableCell: {
    textAlign: "center"
  },
  subHeader: {
    textAlign: "center",
    fontWeight: 700
  }

}
const DecoratedTextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

export const MaterialSupplyOrderReport = () => {
  const dispatch = useDispatch();
  const [isDownloading, setIsDownloading] = useState(false)
  const [isPrinting, setIsPrinting] = useState(false)
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [supplyOrderList, setSupplyOrderList] = useState()
  const { projectLocationListForAgency } = useSelector((state) => state?.resourceForecast)
  const [tableData, setTableData] = useState([])
  const [filteredTableData,setFilteredTableData]=useState([])
  const [isNoFilterData,setIsNoFilterData]=useState(false)
  const [totalAmount, setTotalAmount] = useState()
  const [filteredTotalAmount, setFilteredTotalAmount] = useState()
  const [state, setState] = useState({
    project_location_id: '',
  })
  const [reportType, setReportType] = useState("supply_order")


  useEffect(() => {
    dispatch(getAllProjectLocationByAgency(showLoader, hideLoader, showToast))
  }, []);

  const showLoader = () => {
    setLoading(true)
  }
  const handleReport = (type) => {
    setReportType(type)
    setTableData([])
    setFilteredTableData([])
    setIsNoFilterData(false)
    setState({...state,from_date:"",to_date:""})
  }
  const showToast = (message, type) => {
    const key = enqueueSnackbar(message ? message : 'Something went wrong', { variant: type === 'info' ? 'info' : type==="success"?'success':'error' });
    type === "info" ? SnackBarProperty.info(key, closeSnackbar) :type==="success"?SnackBarProperty.success(key, closeSnackbar): SnackBarProperty.error(key, closeSnackbar)

  }

  const hideLoader = () => {
    setLoading(false)
  }

  const handleChange = (event, source) => {

    if (event.target.name === "project_location_id") {
      setTableData([])
      setSupplyOrderList()
      const filteredArray = projectLocationListForAgency.filter(project => {
        return project.project_location_id === event.target.value
      });


      setState({
        [event.target.name]: event.target.value,
        project_name: filteredArray[0].project_name,
        project_location_id: filteredArray[0].project_location_id,
        project_id: filteredArray[0].project_id,
        project_location: filteredArray[0].project_location

      });
      setLoading(true)
      getAllSupplyOrderForReport({ project_location_id: event.target.value }).then(res => {
        setLoading(false)

        if (res?.status < 300) {
          if (res?.data?.length > 0) {
            setSupplyOrderList(res?.data)
          }
          else {
            const key = enqueueSnackbar("No Supply order found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }


        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setLoading(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })

    } 
    else {
      setTableData([])
      setFilteredTableData([])
      const filteredArray = supplyOrderList.filter(order => {
        return order.supplyorder_id === event.target.value
      });
      setState({
        ...state, [event.target.name]: event.target.value,
        order_date: filteredArray[0]?.supplyorder_date,
        order_no: filteredArray[0]?.supplyorder_no,
        supplier: filteredArray[0]?.supplier
      });
    }
  }


  const handleFormSubmit = () => {
    setTableData([])
    setFilteredTableData([])
    setLoading(true)
    setIsNoFilterData(false)
    if (reportType === "supply_order") {
      getSupplyOrderDetailsReport(state?.project_location_id, state?.supplyorder_id).then(res => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.obj?.item?.supply_order_details_report?.length > 0) {
            setTableData(res?.data?.obj?.item?.supply_order_details_report)
            setFilteredTableData(res?.data?.obj?.item?.supply_order_details_report)
          }
          else {
            const key = enqueueSnackbar("No items found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setLoading(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    }
    else {
      getReceiptDetailsReport(state?.project_location_id, state?.supplyorder_id).then(res => {
        setLoading(false)
        if (res?.status < 300) {
          if (res?.data?.obj?.item?.receipt_details_report?.length > 0) {
            setTableData(res?.data?.obj?.item?.receipt_details_report)
            setFilteredTableData(res?.data?.obj?.item?.receipt_details_report)
          }
          else {
            const key = enqueueSnackbar("No items found", { variant: 'info' });
            SnackBarProperty.info(key, closeSnackbar);
          }

        }
        else {
          const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
          SnackBarProperty.error(key, closeSnackbar);

        }
      }).catch(err => {
        setLoading(false)
        const key = enqueueSnackbar(err?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      })
    }
  }

  useEffect(() => {
    if (tableData?.length > 0) {
      const total = tableData?.reduce((acc, item) => acc + item?.amount, 0)
      setTotalAmount(total)
    }
  }, [tableData])
  useEffect(() => {
    if (filteredTableData?.length > 0) {
      const total = filteredTableData?.reduce((acc, item) => acc + item?.amount, 0)
      setFilteredTotalAmount(total)
    }
  }, [filteredTableData])


  const handleReportDownload = (isPrint) => {
    const doc = new jsPDF();
    const contentHeight = 150;
    if (isPrint) {
      setIsPrinting(true)
      setIsDownloading(false)
    }
    else {
      setIsPrinting(false)
      setIsDownloading(true)
    }
    const headerImage = document.getElementById("headerLogo"); // Assuming you have an element with id "header" for the header image

    doc.setFontSize(12); // Set font size


    // html2canvas(headerImage, {
    //   useCORS: true, // Add this line if the image is from a different domain
    //   allowTaint: true, // Add this line if the image is from a different domain
    // }).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");

    // Calculate the width and height of the header image based on the PDF page width
    //   const pdfWidth = doc.internal.pageSize.getWidth() - 50;
    //   const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
    //   const headerHeight = pdfHeight;
    //   const tableY = headerHeight + 15;
    // Insert the header image into the PDF
    const topBorderPadding = 25; // Adjust the padding value as needed
    doc.setDrawColor(255, 255, 255); // Black color for border
    doc.setLineWidth(topBorderPadding); // Border width (padding)

    // Draw the top border (padding)
    // doc.line(0, topBorderPadding / 2, pdfWidth, topBorderPadding / 2);

    // Insert the header image into the PDF
    //   doc.addImage(imgData, "PNG", 25, topBorderPadding, pdfWidth, pdfHeight);
    const textX = 15;
    const textY = 20 + 5;
    doc.setFont('helvetica', 'bold'); // Set font to bold
    doc.setFontSize(12); // Set font size

    doc.text("Material Supply Order / Receipt Details Report", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth("Material Supply Order / Receipt Details Report") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), topBorderPadding);
    doc.setFontSize(10);
    doc.text(reportType === "receipt" ? "Receipt Details" : "Supply Order", (doc.internal.pageSize.getWidth() / 2) - ((doc.getStringUnitWidth(reportType === "receipt" ? "Receipt Details" : "Supply Order") * doc.internal.getFontSize() / doc.internal.scaleFactor) / 2), topBorderPadding + 10);

    doc.setFontSize(8); // Set font size
    doc.text("Project Location:", 15, topBorderPadding + 20);
    const firstTextWidth = doc.getStringUnitWidth("Project Location:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.project_location}`, 15 + firstTextWidth + 5, topBorderPadding + 20);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Name:", 15, topBorderPadding + 25);
    const secondTextWidth = doc.getStringUnitWidth("Project Name:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.project_name}`, 15 + secondTextWidth + 5, topBorderPadding + 25);
    doc.setFont('helvetica', 'bold');
    doc.text("Project Id:", 15, topBorderPadding + 30);
    const thirdTextWidth = doc.getStringUnitWidth("Project Id:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.project_id}`, 15 + thirdTextWidth + 5, topBorderPadding + 30);
    doc.setFont('helvetica', 'bold');
    doc.text("Supply Order No.:", 15, topBorderPadding + 35);
    const fourthTextWidth = doc.getStringUnitWidth("Supply Order No.:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.order_no}`, 15 + fourthTextWidth + 5, topBorderPadding + 35);
    doc.setFont('helvetica', 'bold');
    doc.text("Supply Order Date:", 15, topBorderPadding + 40);
    const fifthText = doc.getStringUnitWidth("Supply Order Date:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${moment(state?.order_date).format("DD-MM-YYYY")}`, 15 + fifthText + 5, topBorderPadding + 40);
    doc.setFont('helvetica', 'bold');
    doc.text("Supplier:", 15, topBorderPadding + 45);
    const sixthTextWidth = doc.getStringUnitWidth("Supplier:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    doc.setFont('helvetica', 'normal');
    doc.text(`${state?.supplier}`, 15 + sixthTextWidth + 5, topBorderPadding + 45);
    //   doc.setFont('helvetica', 'bold');
    //   doc.text("Requisition Year:", 15, topBorderPadding + 50);
    //   const seventhTextWidth = doc.getStringUnitWidth("Requisition Year:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    //   doc.setFont('helvetica', 'normal');
    //   doc.text(`${state?.req_year}`, 15 + seventhTextWidth + 5, topBorderPadding + 50);
    //   doc.setFont('helvetica', 'bold');
    //   doc.text("Category:", 15, topBorderPadding + 55);
    //   const eighthTextWidth = doc.getStringUnitWidth("Category:") * doc.internal.getFontSize() / doc.internal.scaleFactor;
    //   doc.setFont('helvetica', 'normal');
    //   doc.text(`${state?.category}`, 15 + eighthTextWidth + 5, topBorderPadding + 55);

    doc.setFont('helvetica', 'normal');

    const headerSectionHeight = 60; // Adjust the value as needed

    // Calculate the starting Y-coordinate for the table
    const tableStartY = headerSectionHeight;
    doc.setFont('helvetica', 'normal');
    const body = []


    filteredTableData && filteredTableData?.length > 0 && filteredTableData?.forEach((elem, index) => {

      if (reportType === "supply_order") {
        body.push(
          [index + 1,
          elem?.item_code ? elem?.item_code : "",
          elem?.unit_name ? elem?.unit_name : "",
          { content: parseFloat(elem?.quanttity ? elem?.quanttity : 0).toFixed(2), styles: { halign: "right" } },
          { content: formatNumberWithCommasAndDecimals(elem?.rate ? elem?.rate : 0), styles: { halign: "right" } },
          { content: formatNumberWithCommasAndDecimals(elem?.amount ? elem?.amount : 0), styles: { halign: "right" } }
          ]
        )
      }
      else {
        body.push(
          [index + 1,
          elem?.item_code ? elem?.item_code : "",
          elem?.unit_name ? elem?.unit_name : "",
          { content: parseFloat(elem?.ordered_quantity ? elem?.ordered_quantity : 0).toFixed(2), styles: { halign: "right" } },
          { content: parseFloat(elem?.received_quantity ? elem?.received_quantity : 0).toFixed(2), styles: { halign: "right" } },
          { content: elem?.received_on ? moment(elem?.received_on).format("DD-MM-YYYY") : "", styles: { halign: "center" } },
          { content: formatNumberWithCommasAndDecimals(elem?.rate ? elem?.rate : 0), styles: { halign: "right" } },
          { content: formatNumberWithCommasAndDecimals(elem?.amount ? elem?.amount : 0), styles: { halign: "right" } }
          ]
        )
      }



    })
    body.push([
      { content: "Total Amount (Nu)", colSpan: reportType === "receipt" ? 7 : 5, styles: { halign: "center", valign: "middle", fontStyle: "bold" } },
      { content: formatNumberWithCommasAndDecimals(totalAmount ? totalAmount : 0), styles: { halign: "right", valign: "middle", fontStyle: "bold" } }
    ])
    //   if (tableData?.length > 0) {
    //     body.push([{ content: "Grand Total", colSpan: 6, styles: { fontStyle: "bold", valign: "middle", halign: "right" } }, { content: formatNumberWithCommasAndDecimals(totalAmount), styles: { halign: "right", fontStyle: "bold" } }])
    //   }
    const supplyOrderHeader = [
      { content: "SL No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Item Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Quantity", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Rate (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
    ]
    const receiptDeatilsHeaders = [
      { content: "SL No.", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Item Code", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Unit", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Ordered Quantity", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Received Quantity", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Received On", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Rate (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
      { content: "Amount (Nu)", colSpan: 1, styles: { valign: "middle", halign: "center", cellPadding: 2, fontSize: 8 } },
    ]
    doc.autoTable({
      startY: topBorderPadding + 50,
      head: [reportType === "receipt" ? receiptDeatilsHeaders : supplyOrderHeader],
      body: body, // Use the transformed data here
      theme: "grid",
      styles: {
        fillColor: [255, 255, 255], // Remove background color from the table
        lineColor: [0, 0, 0], // Set border color for main table
        lineWidth: 0.1, // Set border width for main table
        textColor: [0, 0, 0], // Set text color to black
        cellPadding: 2, // Add padding to cells
      },
      // columnStyles: {
      //   2: { cellWidth: 50 }, // "ITEM DESCRIPTION" column width
      // },
    });

    // Function to add footer on each page
    //   function addFooter(pageNumber) {
    //     // const footerText = "Hello CMS User";
    //     // const pageString = "Page " + pageNumber + " of " + doc.internal.getNumberOfPages();
    //     const footerX = 0; // Starting X position for the footer
    //     const footerY = doc.internal.pageSize.getHeight() - 10; // 10 units from the bottom
    //     const pdfWidth = doc.internal.pageSize.getWidth();
    //     const pdfHeight = 10;
     //   doc.addImage(`${ServiceUrl?.showImageUrl}${state?.footer}`, "PNG", footerX, footerY, pdfWidth, pdfHeight, 'FAST')
    //   }

    // Get the total number of pages
    //   const totalNumPages = doc.internal.getNumberOfPages();

    //   // Loop through each page to add the footer with page numbers
    //   for (let i = 1; i <= totalNumPages; i++) {
    //     doc.setPage(i);
    //     addFooter(i);
    //   }


    const scaleFactor = 1.0; // Adjust this value as needed
    const pdfBlob = doc.output('blob', { scale: scaleFactor });

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open a new window with the PDF URL

    if (isPrint) {
      const newWindow = window.open(pdfUrl, '_blank');

      // After the new window is opened, you can close the URL object
      URL.revokeObjectURL(pdfUrl);
      if (newWindow) {
        newWindow.onload = () => {
          setIsPrinting(false)
          setIsDownloading(false)
          // Wait for the window to load and then trigger the print dialog
          newWindow.print();
        };
      }
    }

    setIsPrinting(false)
    setIsDownloading(false)

    isPrint === false && doc.save(reportType === "receipt" ? "Receipt_Details_Report.pdf" : 'Material_Supply_Order_Report.pdf');


  };
  const handleFilter=()=>{
   
    const filteredOrders = tableData.filter(data => {
      const orderDate = moment(reportType === "receipt" ?data?.received_on:data?.supplyorder_date).format("YYYY-MM-DD");

      return moment(orderDate).isBetween(state?.from_date, state?.to_date, null, '[]'); // '[]' includes the boundaries
  });
  if(filteredOrders?.length>0){
    setFilteredTableData(filteredOrders)
    // showToast("Applied Successful","success")
  } else{
    setIsNoFilterData(true)
    showToast("No items found in this date range","info")
  }
  }

  return (
    <>
      <Container>
        <div style={{ height: 'auto', width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>

          <Grid container>

            <Grid item xs={12}>
              <Button
                size="small"
                sx={{ width: "100px" }}
                variant="contained"
                color="error"
                onClick={() => { window.history.back() }}
              >
                <Icon color="primary" fontSize="small">
                  skip_previous
                </Icon>
                Back
              </Button>
            </Grid>
          </Grid>
          <SimpleCard>

            <ValidatorForm onSubmit={handleFormSubmit}>
              <Grid container spacing={3} style={{ marginTop: '-45px', marginBottom: '15px' }} >
                <Grid item xs={12}><b className="font20 extraBold" style={{ color: "#ebad4e" }}>Material Supply Order / Receipt Details</b></Grid>
              </Grid>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <RadioGroup
                    row
                    name="reportType"
                    value={reportType}
                    onChange={(e) => { handleReport(e.target.value) }}
                  >

                    <FormControlLabel
                      label="Material Supply Order"
                      value="supply_order"
                      control={<Radio size="small" color="secondary" />}
                      sx={{ mr: 3, height: 20 }}
                    />
                    <FormControlLabel
                      label="Receipt Details"
                      value="receipt"
                      control={<Radio size="small" color="secondary" />}
                      sx={{ height: 20 }}
                    />
                  </RadioGroup>

                </Grid>


                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Project ID"
                    id="project_location_id"
                    variant="outlined"
                    name="project_location_id"
                    value={state?.project_location_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Project ID is required"]}
                  >
                    {projectLocationListForAgency?.map((item) => (
                      <MenuItem value={item?.project_location_id} key={item?.project_id}>
                        {item?.project_id}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <DecoratedTextField
                    select
                    size="small"
                    fullWidth
                    sx={{ mb: 3 }}
                    label="Supply Order No."
                    id="supplyorder_id"
                    variant="outlined"
                    name="supplyorder_id"
                    value={state?.supplyorder_id}
                    onChange={handleChange}
                    validators={["required"]}
                    errorMessages={["Supply Order No. is required"]}
                  >
                    {supplyOrderList?.map((item) => (
                      <MenuItem value={item?.supplyorder_id} key={item?.supplyorder_id}>
                        {item?.supplyorder_no}
                      </MenuItem>
                    ))}
                  </DecoratedTextField>
                </Grid>
               

                <Grid item sm={4} xs={12}>


                  <Button size="medium" color="success" variant="contained" type="submit" style={{ marginLeft: 4 }} >View</Button>

                </Grid>

              </Grid>

            </ValidatorForm>
          </SimpleCard>
          {
            filteredTableData && filteredTableData?.length > 0 &&
            <Grid container >
              <Grid item xs={12}>
                <Box component="span" display="flex" justifyContent="flex-end">
                  <Button
                    size="small"
                    sx={{ width: "130px", marginLeft: 4 }}
                    variant="contained"
                    color="warning"
                    disabled={isNoFilterData===false?false:true}
                    startIcon={<CloudDownload />}
                    onClick={() => { handleReportDownload(false) }}
                  >
                    Download
                  </Button>
                  <Button
                    size="small"
                    sx={{ width: "100px", marginLeft: 2 }}
                    variant="contained"
                    color="primary"
                    disabled={isNoFilterData===false?false:true}
                    startIcon={<Print />}
                    onClick={() => { handleReportDownload(true) }}
                  >
                    Print
                  </Button>
                </Box>
              </Grid>

            </Grid>}
          
          {filteredTableData && filteredTableData?.length > 0&&<Grid container spacing={3}>
            <Grid item sm={3} xs={12}>
            <TextField
                    fullWidth
                    label="From Date"
                    size="small"
                    type="date"
                    value={state?.from_date ?? ''}
                    InputLabelProps={{ shrink: true, }}
                    variant="outlined"
                    name="from_date"
                    id="from_date"
                    inputProps={{max: moment().format("YYYY-MM-DD") }}
                    onChange={(e)=>{setState({...state,from_date:e.target.value})}}

                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                <TextField
                    fullWidth
                    label="To Date"
                    size="small"
                    type="date"
                    value={state?.to_date ?? ''}
                    InputLabelProps={{ shrink: true, }}
                    variant="outlined"
                    name="to_date"
                    id="to_date"
                    inputProps={{max: moment().format("YYYY-MM-DD") }}
                     onChange={(e)=>{setState({...state,to_date:e.target.value})}}

                  />
                </Grid>
                <Grid item sm={5} xs={12}>
                <Button
                    size="small"
                    sx={{ width: "100px", marginLeft: 2 }}
                    variant="contained"
                    color="primary"
                    // startIcon={<Sync />}
                    disabled={state?.from_date&&state?.to_date?false:true}
                    onClick={()=>{handleFilter()}}
                  >
                    Apply
                  </Button>
                <Button
                    size="small"
                    sx={{ width: "100px", marginLeft: 2 }}
                    variant="contained"
                    color="primary"
                    startIcon={<Sync />}
                    disabled={state?.from_date||state?.to_date?false:true}
                    onClick={()=>{setState({...state,from_date:"",to_date:""});setFilteredTableData(tableData);setIsNoFilterData(false)}}
                  >
                    Refresh
                  </Button>
                </Grid>
            </Grid>}
          {
            filteredTableData && filteredTableData?.length > 0 &&
            <Card sx={{ padding: 3 }}>

              <div style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
                <div id="header" style={{ width: "100%", display: 'flex', flexDirection: "column", gap: "50px" }}>
                  {/* <div style={{ width: "100%", display: "flex", justifyContent: "center", gap: "20px", alignItems: "center" }}>
          <img id="headerLogo" src={`${ServiceUrl?.showImageUrl}${state?.header}`} style={{ height: "150px", width: "auto" }} alt="img" />

        </div> */}

                  <Grid item xs={12} align='center' >
                    <FormLabel sx={{ fontSize: 22 }} ><b>Material Supply Order / Receipt Details Report</b></FormLabel>
                  </Grid>
                  <Grid item xs={12} align='center' >
                    <FormLabel sx={{ fontSize: 18 }} ><b>{reportType === "receipt" ? "Receipt Details" : "Supply Order"}</b></FormLabel>
                  </Grid>


                  <Grid container >
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Location: </b>{state?.project_location}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Name: </b>{state?.project_name}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Project Id: </b>{state?.project_id}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Supply Order No.: </b>{state?.order_no}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Supply Order Date: </b>{moment(state?.order_date).format("DD-MM-YYYY")}</FormLabel>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2 }}>
                      <FormLabel sx={{ fontSize: 18 }}><b>Supplier: </b>{state?.supplier}</FormLabel>
                    </Grid>



                  </Grid>
                </div>

                <div id="filteredTableData">
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead style={{ backgroundColor: '#87CEEB' }}>
                        <TableRow>
                          <TableCell align="center">SL No.</TableCell>
                          <TableCell align="center">Item Code</TableCell>
                          <TableCell align="center">Unit</TableCell>
                          {/* {<TableCell align="center">Quantity</TableCell>} */}
                          {reportType === "receipt" ? <>
                            <TableCell align="center">Ordered Quantity</TableCell>
                            <TableCell align="center">Received Quantity</TableCell>
                            <TableCell align="center">Received on</TableCell>
                          </> :
                            <TableCell align="center">Quantity</TableCell>
                          }
                          <TableCell align="center">Rate (Nu)</TableCell>
                          <TableCell align="center">Amount (Nu)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                         filteredTableData?.length>0&&!isNoFilterData&& filteredTableData?.map((elem, index) => {
                            return (
                              <TableRow>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell align="center">{elem?.item_code}</TableCell>
                                <TableCell align="center">{elem?.unit_name}</TableCell>
                                {reportType === "receipt" ? <>
                                  <TableCell align="right">{parseFloat(elem?.ordered_quantity ? elem?.ordered_quantity : 0).toFixed(2)}</TableCell>
                                  <TableCell align="right">{parseFloat(elem?.received_quantity ? elem?.received_quantity : 0).toFixed(2)}</TableCell>
                                  <TableCell align="right">{elem?.received_on ? moment(elem?.received_on).format("DD-MM-YYYY") : ""}</TableCell>
                                </> :
                                  <TableCell align="right">{parseFloat(elem?.quanttity ? elem?.quanttity : 0).toFixed(2)}</TableCell>}

                                <TableCell align="right">{formatNumberWithCommasAndDecimals(elem?.rate ? elem?.rate : 0)}</TableCell>
                                <TableCell align="right">{formatNumberWithCommasAndDecimals(elem?.amount ? elem?.amount : 0)}</TableCell>
                              </TableRow>
                            )
                          })

                        }
                       { filteredTableData?.length>0&&!isNoFilterData&& <TableRow>
                          <TableCell align="right" colSpan={reportType === "receipt" ? 7 : 5}><b>Total Amount (Nu)</b></TableCell>
                          <TableCell align="right" ><b>{formatNumberWithCommasAndDecimals(filteredTotalAmount ? filteredTotalAmount : 0)}</b></TableCell>
                        </TableRow>}
                        {/* {tableData?.length > 0 &&
                <TableRow>
                  <TableCell colSpan={6} align="right"><b>Grand Total</b></TableCell>
                  <TableCell align="right"><b>{formatNumberWithCommasAndDecimals(totalAmount)}</b></TableCell>
                </TableRow>
              } */}

                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>
              </div>
            </Card>}

        </div>
        {loading && (
          <Loader
            show={loading}
          />
        )}
        {(isPrinting || isDownloading) && (
          <Loader
            show={isPrinting ? isPrinting : isDownloading}
            text="Please Wait While Generating PDF..."
          />
        )}
      </Container>
    </>
  )
}