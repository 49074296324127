import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, MenuItem, styled } from "@mui/material";
import { Loader } from "app/components";
import { FlexBetween } from "app/components/FlexBox";
import { H4 } from "app/components/Typography";
import { GET_CONSTRUCTION_NAME_LIST, getAllConstructionTypeList, getAllProjectLocationListForConstruction, saveOrUpdateConstructionName } from "app/redux/actions/ConstructionActions";
import { AppConstants } from "app/utils/AppConstants";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, DecimalInputFieldWithValidation, NumberFormatCustom } from "app/utils/utils";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const NewConstructionNameDialog = ({ title, classificationList, parentState, constructionName, open, handleClose, handleSuccess }) => {
  const [state, setState] = useState({
    project_name: "",
    project_location: "",
    projectlocation_id: "",
    project_id: "",
    construction_name: "",
    constructiontype_name: "",
    constructiontype_id: "",
    description: "",
    constructionname_id: "",
    agency_id: "",
    created_by: "",
    created_on: "",
    boq_code: '',
    classification_id: '',
    classification_name: '',
    plinth_area: '',
    bsr_year: ''
  });
  const [uniqueCode, setUniqueCode] = useState()
  const { projectLocationList, constructionTypeList } = useSelector((state) => state.construction);

  // useEffect(() => {
  //   console.log("parent state:",constructionName)
  //   if (parentState && Object.keys(parentState).length > 0) {
  //     setState({ ...state, ...parentState, ["projectlocation_id"]: parentState?.project_location_id })
  //     console.log("split values",parentState?.boq_code?.split('/'))

  //   }
  // }, [constructionName])
  useEffect(() => {
    console.log("state", parentState)
    console.log("unique code:", uniqueCode)
  }, [state])

  useEffect(() => {
    if (checkValidation(parentState?.project_id) === true) {
      setState({
        ...state, ['projectlocation_id']: parentState?.project_location_id,
        ['project_location']: parentState?.project_location,
        ['project_id']: parentState?.project_id,
        ['project_name']: parentState?.project_name
      })
    }
  }, [parentState])

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChange = (event, source) => {
    if (event.target.name === "constructiontype_name") {
      const filteredArray = constructionTypeList.filter(constructiontype => {
        return constructiontype.constructiontype_name === event.target.value
      });
      state.constructiontype_id = filteredArray[0].constructiontype_id
      setState({ ...state, [event.target.name]: event.target.value });
    }
    else if (event.target.name === "project_location") {
      const filteredArray = projectLocationList.filter(projectLocation => {
        return projectLocation.project_location_id === event.target.value
      });
      state.projectlocation_id = filteredArray[0].project_location_id
      state.project_name = filteredArray[0].project_name
      state.bsr_year = filteredArray[0].bsr_year
      // state.constructionNameBOQList = [{ "bsr_year": filteredArray[0].bsr_year }]
      state.boq_code = generateBoqCode(filteredArray[0].project_location, filteredArray[0].bsr_year, state.construction_name)
      setState({ ...state, [event.target.name]: event.target.value, project_location: filteredArray[0].project_location, projectlocation_id: filteredArray[0].project_location_id, project_name: filteredArray[0].project_name, bsr_year: filteredArray[0].bsr_year });

    } else if (event.target.name === "construction_name") {
      ;

      state.boq_code = generateBoqCode(state.project_location, state.bsr_year, event.target.value)
      setState({ ...state, [event.target.name]: event.target.value });
    } else if (event.target.name === "classification_name") {
      const filteredArray = classificationList.filter(classification => {
        return classification.classification_name === event.target.value
      });
      state.classification_id = filteredArray[0].classification_id
      setState({ ...state, [event.target.name]: event.target.value });
    } else {
      ;
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  function generateBoqCode(project_name, bsr_year, contructionName) {
    if (project_name === null || project_name === undefined || project_name === '') {
      return ''
    }
    if (contructionName === null || contructionName === undefined || contructionName === '') {
      return `${project_name ?? ''}`
    }
    return `${project_name ?? ''}/${contructionName ?? ''}/${bsr_year}${title === "Update Construction Name" ? '/' + uniqueCode : ''}`
  }

  const handleFormSubmit = () => {
    setLoading(true)
    saveOrUpdateConstructionName({ ...state }).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success) {
        dispatch({
          type: GET_CONSTRUCTION_NAME_LIST,
          payload: res?.data?.obj,
        });
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      }
      handleSuccess()
    }).catch(function (error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  };

  useEffect(() => {
    if (constructionName && Object.keys(constructionName).length > 0) {
      setState({ ...constructionName })
      if (constructionName?.boq_code?.split('/')?.length === 4) {
        setState({ ...state, ...constructionName, bsr_year: parseInt(constructionName?.boq_code?.split('/')[2]) })
        setUniqueCode(constructionName?.boq_code?.split('/')[3])
      }
    }

  }, [constructionName]);

  const showLoader = () => {
    setLoading(true)
  }

  const hideLoader = () => {
    setLoading(false)
  }

  const showToast = (msg) => {
    const key = enqueueSnackbar(msg ?? AppConstants.somethingWentWrong, { variant: 'error' });
    SnackBarProperty.error(key, closeSnackbar);

  }

  const fetchDataSequentially = async () => {

    console.log(projectLocationList, constructionTypeList)
    if (projectLocationList?.length === 0 && constructionTypeList?.length === 0) {
      try {
        dispatch(getAllProjectLocationListForConstruction(showLoader, false, showToast))
        await new Promise((resolve) => setTimeout(resolve, 1000));
        dispatch(getAllConstructionTypeList(false, hideLoader, showToast))
      } catch {
        showToast(AppConstants.somethingWentWrong)
      }
    } else if (projectLocationList?.length > 0 && constructionTypeList?.length === 0) {
      dispatch(getAllConstructionTypeList(showLoader, hideLoader, showToast))
    } else if (projectLocationList?.length === 0 && constructionTypeList?.length > 0) {
      dispatch(getAllProjectLocationListForConstruction(showLoader, hideLoader, showToast))
    }
  }

  useEffect(() => {
    fetchDataSequentially()
  }, []);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth={true}>
      <Box p={3}>
        <H4 sx={{ mb: "20px" }}>{title}</H4>

        <ValidatorForm onSubmit={handleFormSubmit}>
          {
            state?.project_id ?
              <TextField
                size="small"
                type="text"
                name="project_location"
                label="Project Location"
                value={state?.project_location}
                disabled
                validators={["required"]}
                errorMessages={["Project Location is required"]} >
              </TextField> :
              <TextField
                size="small"
                select
                type="text"
                name="project_location"
                label="Project Location ID"
                value={state?.projectlocation_id}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["Project Location is required"]} >
                {projectLocationList?.map((item) => (
                  <MenuItem disabled={item?.status === 7 ? true : false} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }} value={item?.project_location_id} key={item?.project_location_id}>
                    {item?.project_id}
                    {item?.status === 7 && <span style={{ color: "red", fontSize: "12px" }}>This project is already approved.</span>}
                  </MenuItem>
                ))}
              </TextField>
          }

          <TextField
            size="small"
            disabled
            type="text"
            variant="outlined"
            name="project_name"
            label="Project Name"
            value={state?.project_name ?? ''}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={["Project Name is required"]}>

          </TextField>
          <TextField
            size="small"
            select
            type="text"
            name="constructiontype_name"
            label="Construction Type"
            value={state?.constructiontype_name}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={["Construction Type is required"]}>
            {constructionTypeList?.map((item) => (
              <MenuItem value={item?.constructiontype_name} key={item?.constructiontype_id}>
                {item?.constructiontype_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            type="text"
            name="construction_name"
            label="Construction Name"
            value={state?.construction_name}
            inputProps={{ maxLength: 30 }}
            onChange={handleChange}
            validators={["required"]}
            onKeyDown={(event) => {
              if (
                event?.key === "/") {
                event.preventDefault();
              }

            }}
            errorMessages={["Construction Name is required"]}
          />
          <DecimalInputFieldWithValidation
            size="small"
            fullWidth
            id="plinth_area"
            name="plinth_area"
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            onChange={(event) => {
              setState({ ...state, [event.target.name]: event.target.value });
            }
            }
            label="Plinth Area (sq. m)"
            sx={{ mb: 2 }}
            value={state?.plinth_area || ""}
            //validators={["required"]}
            onKeyDown={(event) => {
              if (event?.code === "ArrowDown" || event?.code === "ArrowUp" ||
                event?.key === "-" || event?.key === "+" || event?.key?.toLowerCase() === "e") {
                event.preventDefault();
              }
            }}
            errorMessages={["Plinth Area is required"]}>
          </DecimalInputFieldWithValidation>
          <TextField
            size="small"
            select
            type="text"
            id="classification_name"
            name="classification_name"
            label="Classification"
            value={state?.classification_name}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={["Classification is required"]}>
            {classificationList?.map((item) => (
              <MenuItem value={item?.classification_name} key={item?.classification_id}>
                {item?.classification_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            type="text"
            rows={3}
            multiline={true}
            name="description"
            label="Description"
            value={state?.description}
            onChange={handleChange}
            validators={["required"]}
            errorMessages={["Description is required"]}
          />

          <TextField
            size="small"
            type="text"
            name="boq_code"
            id="boq_code"
            placeholder="BoQ Code"
            disabled
            value={state?.boq_code}
          //validators={["required"]}
          //errorMessages={["Description is required"]}
          />
          {/* </Grid>
          </Grid> */}

          <FlexBetween>
            <LoadingButton variant="contained" color="primary" type="submit" loading={loading}>
              Save
            </LoadingButton>
            <Button variant="outlined" color="error" onClick={() => handleClose()}>
              Close
            </Button>
          </FlexBetween>
        </ValidatorForm>
      </Box>
      {loading && (
        <Loader
          show={loading}
        />
      )}
    </Dialog>
  );
};

export default NewConstructionNameDialog;
