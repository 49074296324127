import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, Fab, Grid, Icon, styled } from "@mui/material";
import { FlexBetween } from "app/components/FlexBox";
import { H4 } from "app/components/Typography";
import { downloadImplementationFile, saveOrUpdateAdvancePayment } from "app/redux/actions/ImplementationActions";
import { AppConstants } from "app/utils/AppConstants";
import { ServiceUrl } from "app/utils/Constants";
import { ProjectStatus } from "app/utils/ProjectStatus";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { checkValidation, currencyFormat, getTwoDigitDecimal, NumberFormatCustom } from "app/utils/utils";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";
import { cmsDateFormat } from "app/utils/constant";

const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const DecimalField = styled(TextField)(({ theme }) => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=text]": {
    textAlign: "right"
  }
}));

const FabIcon = styled(Fab)(() => ({
  textTransform: "capitalize",
  "& .icon": { marginRight: "8px" },
}));


const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const HiddenInput = styled("input")(() => ({ display: "none" }));


const AdvancePaymentVerificationDialog = ({ title, project, advancePayment, open, handleClose, handleSuccess }) => {

  const [verificationArray, setVerificationArray] = useState(["Approved", "Rejected"])

  const [state, setState] = useState({
    project_name: "",
    project_id: '',
    project_location_id: "",
    advancetype_id: "",
    advancetype_name: '',
    advance_no: "",
    advance_date: "",
    advance_amount: 0,
    description: '',
    status_id: ProjectStatus.RECOMMENDED,
    status_name: 'Recommended',
    file_name: '',
    file: '',
    recommended_amount: '',
    review_remarks: '',
    review_file_name: '',
    review_file: '',
    approved_amount: 0,
    approval_remarks: '',
    recommended_remarks: '',
    verified_by: '',
    verified_on: ''

  });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);


  const handleChange = (event, source) => {

    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleFileSelect = (event) => {
    let files = event.target.files;
    if (files.length > 0) {
      state.review_file_name = files[0].name
      setState({ ...state, ['review_file']: files[0] });
    }
  };

  const handleFormSubmit = (status) => {
    if (status === "recommended") {
      setVerifyLoading(true)
    }
    if (status === "rejected") {
      setRejectLoading(true)
    }
    saveOrUpdateAdvancePayment(createParamsForAdvancePayment(status)).then((res) => {
      setVerifyLoading(false)
      setRejectLoading(false)
      console.log('my response code', res?.status)
      if (res?.status < 300 && res?.data?.success) {
        const key = enqueueSnackbar("Advance payment recommended successfully", { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);
        handleSuccess()
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);

      }
    }).catch(function (error) {
      setVerifyLoading(false)
      setRejectLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);


    })
  };

  function createParamsForAdvancePayment(status) {
    const formData = new FormData()
    formData.append('project_location_id', state.project_location_id);
    formData.append('advancepayment_id', state.advancepayment_id)
    formData.append('advancetype_id', state.advancetype_id);
    formData.append('advance_no', state.advance_no);
    formData.append('advance_date', dayjs(state?.advance_date).format("ddd, DD MMM YYYY 00:00:00") + " GMT")
    formData.append('description', state.description);
    formData.append('status_id', status === "recommended" ? ProjectStatus.RECOMMENDED : ProjectStatus.REJECTED);
    if (checkValidation(state?.file_name)) {
      formData.append('file_name', state?.file_name);
    }
    console.log("status:", status.toUpperCase(), status === "approved" ? ProjectStatus.APPROVED : ProjectStatus.REJECTED)
    if (checkValidation(state.recommended_amount)) {
      formData.append('recommended_amount', state.recommended_amount);
    }
    formData.append('status_name', status.toUpperCase());
    formData.append('advance_amount', state.advance_amount ? state.advance_amount : 0);
    formData.append('requested_amount', state.requested_amount ? state.requested_amount : 0);
    // formData.append('review_file_name', state.review_file_name);
    if (checkValidation(state.review_file_name)) {
      formData.append('review_file_name', state.review_file_name)
    }
    if (checkValidation(state.review_remarks)) {
      formData.append('review_remarks', state.review_remarks);
    }
    if (checkValidation(state.approval_remarks)) {
      formData.append('approval_remarks', state.approval_remarks);
    }

    formData.append('approved_amount', state.advancetype_id !== 2 ? state?.advance_amount ? state?.advance_amount : 0 : state.approved_amount ? state.approved_amount : 0);

    formData.append('verified_by', state.verified_by);
    formData.append('verified_on', new Date(state.verified_on));


    state?.copyToDetailsList?.forEach((details, index) => {
      if (checkValidation(details?.address)) {
        formData.append(`copyToDetailsList[${index}].address`, details.address)
      }
      if (checkValidation(details?.email)) {
        formData.append(`copyToDetailsList[${index}].email`, details.email)
      }

      if (checkValidation(details?.copytodetails_id)) {
        formData.append(`copyToDetailsList[${index}].copytodetails_id`, details.copytodetails_id)
        formData.append(`copyToDetailsList[${index}].advancepayment_id`, details.advancepayment_id)
      } else {
        if (checkValidation(details?.address) || checkValidation(details?.email)) {
          formData.append(`copyToDetailsList[${index}].advancepayment_id`, state.advancepayment_id)
        }
      }
    })

    if (state.advancetype_name === "Materials Advance") {

      state?.advancePaymentGoodReceiptMapList?.forEach((e, index) => {
        formData.append(`advancePaymentGoodReceiptMapList[${index}].goodreceipt_id`, e.goodreceipt_id);
        formData.append(`advancePaymentGoodReceiptMapList[${index}].advancepayment_id`, e.advancepayment_id);
        formData.append(`advancePaymentGoodReceiptMapList[${index}].advancepayment_goodreceipt_map_id`, e.advancepayment_goodreceipt_map_id);

      })
    }

    return formData
  }

  function formatStartDate(date) {
    const customDate = new Date(date)
    return moment(customDate).format('YYYY-MM-DD');
  }

  useEffect(() => {
    setState({ ...advancePayment, ['status_name']: "Approved" })
  }, [advancePayment]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth={true}>
      <Box p={3}>
        <H4 sx={{ mb: "20px" }}>{title}</H4>

        <ValidatorForm >
          <Grid sx={{ mb: "10px" }} container spacing={4}>
            <Grid item sm={6} xs={12}>
              <TextField
                size='small'
                label="Project ID"
                disabled
                id="project_id"
                variant="outlined"
                name="project_id"
                value={project?.project_id || ""}
                // onChange={handleChange}
                validators={["required"]}
                errorMessages={["Project ID is required"]}
              >
              </TextField>

              <TextField
                size='small'
                disabled
                label="Advance Type"
                id="advancetype_name"
                variant="outlined"
                name="advancetype_name"
                value={state?.advancetype_name || ""}
                // onChange={handleChange}
                validators={["required"]}
                errorMessages={["Advance Type is required"]}
              >
              </TextField>


              <DatePicker
                readOnly
                sx={{ mb: 2 }}
                label="Advance Date"
                value={dayjs(state?.advance_date)}
                format={cmsDateFormat}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: true,
                    variant: 'outlined',
                    error: false,
                  },
                }}
              />

              <TextField
                size='small'
                type="text"
                rows={3}
                disabled
                multiline={true}
                name="description"
                label="Description"
                value={state?.description}
                // onChange={handleChange}
                validators={["required"]}
                errorMessages={["Description is required"]}
              />
              {state?.file_name &&
                <Box mb={3} display="flex" flexWrap={1}>
                  <FabIcon id="createLinkButton" color="success" component="span" disabled={state?.file_name ? false : true} variant="extended"
                    onClick={() => downloadImplementationFile(state?.file_name, 'AdvancePayment')}>
                    <FlexBox>
                      <Icon className="icon">cloud_download</Icon>
                      <span>View Supporting Document</span>
                    </FlexBox>
                  </FabIcon>
                </Box>
              }
              {state?.advancetype_id === 2 && (
                <DecimalField
                  size='small'
                  disabled
                  name="recommended_amount"
                  id="recommended_amount"
                  label="Recommended Amount (Nu)"
                  value={state?.recommended_amount}
                  onChange={(event) => {
                    setState({ ...state, [event.target.name]: event.target.value});
                    }
                  }
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  validators={["required"]}
                  errorMessages={["Recommend Amount is required"]}
                />
              )}
              <TextField
                size='small'
                disabled
                type="text"
                rows={3}
                multiline={true}
                name="review_remarks"
                label="Review Remarks"
                value={state.review_remarks ? state?.review_remarks : "N/A"}
                // onChange={handleChange}
                validators={["required"]}
                errorMessages={["Remarks is required"]}
              />
              {/* <TextField
                select
                label="Approval Status"
                id="status_name"
                variant="outlined"
                name="status_name"
                value={state?.status_name || ""}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["Approval Status is required"]}
                >
                {verificationArray?.map((item) => (
                    <MenuItem value={item} key={item}>
                        {item}
                    </MenuItem>
                ))}
            </TextField> */}

            </Grid>

            <Grid item sm={6} xs={12}>
              <TextField
                size='small'
                disabled
                type="text"
                name="project_name"
                id="project_name"
                label="Project Name"
                variant="outlined"
                value={project?.project_name}
              />
              <DecimalField
                size='small'
                type="text"
                name="advance_no"
                disabled
                label="Advance No."
                id="advance_no"
                value={state?.advance_no}
                // onChange={handleChange}
                validators={["required"]}
                errorMessages={["Advance No. is required"]}
              />
              <DecimalField
                size='small'
                name="advance_amount"
                disabled
                id="advance_amount"
                label="Submitted Amount (Nu)"
                value={state?.advance_amount}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                // onChange={handleChange}
                validators={["required"]}
                errorMessages={["Amount is required"]}
              />

              {/* <TextField
                size='small'
                disabled
                type="text"
                rows={3}
                multiline={true}
                name="review_remarks"
                label="Review Remarks"
                value={state.review_remarks ? state?.review_remarks : "N/A"}
                // onChange={handleChange}
                validators={["required"]}
                errorMessages={["Remarks is required"]}
              /> */}


              {/* <TextField
                size='small'
                type="text"
                rows={4}
                multiline={true}
                name="recommended_remarks"
                label="Recommended Remarks"
                value={state?.recommended_remarks}
                onChange={handleChange}
              //validators={["required"]}
              //errorMessages={["Recommended Remarks is required"]}
              /> */}
            </Grid>
          </Grid>
          <H4 sx={{ mb: 2 }}>Copy to:</H4>
          {state?.copyToDetailsList?.map((details, index) => (
            <div>
              <Grid sx={{ mb: "16px" }} container spacing={4}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    size='small'
                    disabled
                    label="Email"
                    id="email"
                    variant="outlined"
                    name="email"
                    value={details?.email || "N/A"}
                  // onChange={e => setDetailsTableUpdatedRateValues('Email', index, e.target.value)}
                  >
                  </TextField>
                </Grid>

                <Grid item sm={6} xs={12}>

                  <TextField
                    size='small'
                    disabled
                    type="text"
                    name="address"
                    id="address"
                    label="Address"
                    variant="outlined"
                    value={details?.address || 'N/A'}
                  // onChange={e => setDetailsTableUpdatedRateValues('Address', index, e.target.value)}
                  />
                </Grid>

              </Grid>
            </div>
          ))}

          <FlexBetween>
            <Grid container>
              <Grid item sm={4} xs={5}>
                <LoadingButton variant="contained" disabled={rejectLoading ? true : false} color="success" type="submit" onClick={() => { handleFormSubmit("recommended") }} loading={verifyLoading}>
                  Recommend
                </LoadingButton>
              </Grid>
              <LoadingButton variant="contained" disabled={verifyLoading ? true : false} color="secondary" type="submit" onClick={() => { handleFormSubmit("rejected") }} loading={rejectLoading}>
                Reject
              </LoadingButton>
            </Grid>
            <Button variant="outlined" color="secondary" onClick={() => handleClose()}>
            Close
            </Button>
          </FlexBetween>
        </ValidatorForm>
      </Box>
    </Dialog>
  );
};

export default AdvancePaymentVerificationDialog;
