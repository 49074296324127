import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, styled } from "@mui/material";
import { FlexBetween } from "app/components/FlexBox";
import { H4 } from "app/components/Typography";
import { saveOrUpdateConstructionType } from "app/redux/actions/ConstructionActions";
import { SnackBarProperty } from "app/utils/SnackBarProperty";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import { AppConstants } from "app/utils/AppConstants";


const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

const NewConstructionTypeDialog = ({ title, constructiontype, open, handleClose, handleSuccess }) => {
  const [state, setState] = useState({
    constructiontype_name: ""
  });
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false)

  const handleChange = (event, source) => {
    ;
    setState({ ...state, [event.target.name]: event.target.value.toUpperCase() });    
  };

  const handleFormSubmit = () => {
    setLoading(true)
    saveOrUpdateConstructionType(state).then((res) => {
      setLoading(false)
      if (res?.status < 300 && res?.data?.success) {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'success' });
        SnackBarProperty.success(key, closeSnackbar);
          handleSuccess()
      } else {
        const key = enqueueSnackbar(res?.data?.message, { variant: 'error' });
        SnackBarProperty.error(key, closeSnackbar);
      }
    }).catch(function(error) {
      setLoading(false)
      const key = enqueueSnackbar(error?.response?.data?.errorMessage ?? AppConstants.somethingWentWrong, { variant: 'error' });
      SnackBarProperty.error(key, closeSnackbar);
    })
  };

 useEffect(() => {
    setState({ ...constructiontype })
  }, [constructiontype]);

  return (
    <Dialog  open={open} maxWidth="xs" fullWidth={true}>
      <Box p={3}>
        <H4 sx={{ mb: "20px" }}>{title}</H4>

        <ValidatorForm onSubmit={handleFormSubmit}>
              <TextField
                type="text"
                name="constructiontype_name"
                label="Construction Type"
                value={state?.constructiontype_name}
                onChange={handleChange}
                validators={["required"]}
                errorMessages={["Construction Type is required"]}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />
          <FlexBetween>
          <LoadingButton variant="contained" color="primary" type="submit" loading={loading}> 
              Save
            </LoadingButton>

            <Button variant="outlined" color="secondary" onClick={() => handleClose()}>
            Close
            </Button>
          </FlexBetween>
        </ValidatorForm>
      </Box>
    </Dialog>
  );
};

export default NewConstructionTypeDialog;
